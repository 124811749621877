import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ReservationsService {
  public reservation$ = new Subject<string>();

  public get $reservationChange() {
    return this.reservation$.asObservable();
  }

  public setReservation(guestId: string) {
    this.reservation$.next(guestId);
  }
}
