import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { first } from 'lodash';

@Component({
  selector: 'ui-segments',
  templateUrl: './segments.component.html',
  styleUrls: ['./segments.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SegmentsComponent implements OnInit, OnChanges {
  @Output() selected: EventEmitter<string> = new EventEmitter<string>();
  @Input() segments: any[] = [];
  @Input() current?: any;

  public ngOnInit(): void {
    this.current = first(this.segments ?? [])?.value;
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['current']?.currentValue) {
      this.current = changes['current'].currentValue as any;
    }
  }

  public onSegmentClick(item: any) {
    this.selected.emit(item.value);
  }
}
