import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { generateRandomId } from 'angular-v2-utils';

@Component({
  selector: 'ui-master-buttons',
  templateUrl: './master-buttons.component.html',
  styleUrls: ['./master-buttons.component.scss'],
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MasterButtonsComponent),
      multi: true,
    },
  ],
  imports: [CommonModule, ReactiveFormsModule, TranslateModule, FormsModule],
})
export class MasterButtonsComponent implements ControlValueAccessor, OnInit {
  @Input() values: any[] = [];
  @Input() name = generateRandomId();
  value = null;
  disabled = false;
  private onTouch!: () => void;
  private onModelChange!: (value: any) => void;

  constructor(private translateService: TranslateService) {}

  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: any): void {
    this.value = value;
  }

  valueChange($event: any) {
    if (!this.disabled) {
      this.value = $event;
      this.onModelChange(this.value);
      this.onTouch();
    }
  }

  ngOnInit() {
    if (this.values.length === 0) {
      this.values = [
        {
          label: this.translateService.instant('SH.YES'),
          value: true,
        },
        {
          label: this.translateService.instant('SH.NO'),
          value: false,
        },
      ];
    }
  }
}
