<ui-modal-base
  [padding]="false"
  [header]="'SH.VISITOR_RESERVATION_EDIT_MODAL.TITLE' | translate"
  [subtitle]="'SH.VISITOR_RESERVATION_EDIT_MODAL.SUBTITLE' | translate : { date: stayRange }"
>
  <div content [formGroup]="formGroup" *ngIf="reservation">
    <ui-form label="{{ 'SH.ROOM' | translate }}" [required]="false">
      <ng-select
        class="ng-select-sh ng-select-input-big ng-select-100"
        *ngIf="rooms"
        formControlName="room"
        [clearable]="false"
        [items]="rooms"
        bindValue="value"
        bindLabel="label"
        [multiple]="false"
        [searchable]="true"
        typeToSearchText="{{ 'SH.VISITOR_MODAL.TYPE_TO_SEARCH' | translate }}"
      ></ng-select>
    </ui-form>
    <ui-form class="reservation-form">
      <label>{{ 'SH.RESERVATION' | translate }}</label>
      <div>
        <input
          type="text"
          id="reservation"
          name="reservation"
          formControlName="reservation"
          formInput
          #input
          (focus)="input.blur()"
          [bsConfig]="bsConfig"
          bsDaterangepicker
        />
        <svg-icon src="/assets/icons/icons-calendar-alt.svg"></svg-icon>
      </div>

      <ui-error-message [control]="formGroup.get('reservation')"></ui-error-message>
    </ui-form>

    <ui-form class="button-form">
      <ui-button-rectangle
        (click)="onEditClick()"
        type="primary"
        size="l"
        [label]="'SH.EDIT' | translate"
      ></ui-button-rectangle>
    </ui-form>
  </div>
</ui-modal-base>
