import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
export interface InputConfirmModelText {
  header?: string;
  body?: string;
  submit?: string;
  submit2?: string;
  cancel?: string;
  image?: string;
  btnClass?: string;
}

@Component({
  selector: 'sh-input-modal',
  templateUrl: './input-modal.component.html',
  styleUrls: ['./input-modal.component.scss'],
})
export class InputModalComponent implements OnInit, AfterViewInit {
  @ViewChild('input') input!: ElementRef<HTMLInputElement>;
  @Output() action: EventEmitter<string> = new EventEmitter();
  @Input() text!: InputConfirmModelText;
  @Input() selectValue?: boolean;
  @Input() canBeLeftEmpty = true;
  private cfgDefault: InputConfirmModelText = {
    header: 'HEADER',
    submit: 'SUBMIT 1',
    submit2: 'SUBMIT 2',
  };
  public cfg!: InputConfirmModelText;
  @Input() value!: string;

  get hasError(): boolean {
    return this.value?.length < 1 && !this.canBeLeftEmpty;
  }

  constructor(public bsModalRef: BsModalRef) {}

  ngOnInit() {
    this.cfg = Object.assign({}, this.cfgDefault, this.text);
  }

  onConfirmClick() {
    if (this.value?.trim()?.length < 1) {
      return;
    }
    this.action.emit(this.value);
    this.bsModalRef.hide();
  }

  onCancelClick() {
    this.bsModalRef.hide();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.input) {
        this.input.nativeElement.focus();
        if (this?.selectValue) {
          this.input.nativeElement.select();
        }
      }
    }, 500);
  }
}
