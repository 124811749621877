import { enUS, es, pl } from 'date-fns/locale';
import { Locale } from 'date-fns';

export function setLocale(language: string): Locale {
  switch (language) {
    case 'pl':
      return pl;
    case 'es':
      return es;
    default:
      return enUS;
  }
}
