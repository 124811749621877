import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ITag } from '@smarttypes/core';
import { TagsService } from 'angular-v2-services';

@Component({
  selector: 'ui-guest-tag',
  templateUrl: './guest-tag.component.html',
  styleUrls: ['./guest-tag.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class GuestTagComponent implements OnInit {
  @Input() public type?: string;
  public iconPath?: string;

  constructor(private tagsService: TagsService) {}

  ngOnInit(): void {
    const icon = (this.tagsService.getTags() as ITag[]).find(t => t.value === this.type)?.icon;
    this.iconPath = icon ? `/assets/icons/${icon}.svg` : '';
  }
}
