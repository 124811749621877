import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'ui-empty',
  templateUrl: './empty.component.html',
  styleUrls: ['./empty.component.scss'],
  standalone: true,
  imports: [CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmptyComponent {
  @Input() label?: string;
  @Input() content?: string;
  @HostBinding('class.artwork') @Input() artwork?: string;
  @HostBinding('class.small-padding') @Input() smallPadding = false;
  @HostBinding('class') @Input() image?: 'emptyreq' | 'icons-nothing-found' | 'travolta-no-guests' | 'travolta-empty';

  public get icon(): string | null {
    if (this.artwork) {
      return `/assets/icons/${this.artwork}.svg`;
    }
    switch (this.image) {
      case 'icons-nothing-found':
        return `/assets/icons/${this.image}.svg`;
      case 'travolta-no-guests':
        return '/assets/travolta-no-guests.svg';
      default:
        if (this.image) {
          return `/assets/images/${this.image}.svg`;
        } else {
          return null;
        }
    }
  }
}
