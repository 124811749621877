import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Injector,
  Input,
  OnDestroy,
  ViewEncapsulation,
} from '@angular/core';
import {
  ControlContainer,
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SelectComponent } from '@ui/common/forms';
import { map, Observable, of, Subject, Subscription, switchMap } from 'rxjs';
import { set, uniqBy } from 'lodash';
import { catchError, debounceTime, filter, finalize, tap } from 'rxjs/operators';
import { RoomsService } from '../../services/rooms.service';
import { TranslateModule } from '@ngx-translate/core';
import { CompanyService } from '../../core/services/company.service';
import { covertRoomToSelectOptions, SelectOption } from 'angular-v2-utils';
import { IRoom } from '@smarttypes/hotel';

const PAGINATION_LIMIT = 100;

@Component({
  selector: 'sh-select-room',
  templateUrl: './select-room.component.html',
  styleUrls: ['./select-room.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [CommonModule, ReactiveFormsModule, SelectComponent, TranslateModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectRoomComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SelectRoomComponent),
      multi: true,
    },
  ],
})
export class SelectRoomComponent implements ControlValueAccessor, OnDestroy, AfterViewInit {
  @Input() public formControl!: FormControl;
  @Input() public formControlName = '';
  @Input() public placeholder = '';
  public value = '';
  public roomLoading = false;
  public roomInput$ = new Subject<string>();
  public rooms: SelectOption[] = [];
  private onTouch!: () => void;
  private onChange!: (value: any) => void;
  private isRegister = false;
  private subscription: Subscription = new Subscription();
  private initialValue = '';
  private roomSearchTerm = '';
  private pagination = {
    limit: PAGINATION_LIMIT,
    offset: 0,
    total: 0,
    reachEnd: false,
  };

  constructor(
    private cd: ChangeDetectorRef,
    private roomsService: RoomsService,
    private injector: Injector,
    private companyService: CompanyService,
  ) {}

  get control() {
    return this.formControl || this.controlContainer.control?.get(this.formControlName);
  }

  get controlContainer() {
    return this.injector.get(ControlContainer);
  }

  public loadMoreRooms(initial = false) {
    this.loadRooms(initial).subscribe(() => {
      this.cd.detectChanges();
    });
  }

  public ngAfterViewInit() {
    this.initialValue = this.value;
    this.loadMoreRooms(true);
    this.subscription.add(
      this.searchInput().subscribe(() => {
        this.cd.detectChanges();
      }),
    );
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public validate(c: FormControl) {
    return this.validateFn(c);
  }

  public registerOnChange(fn: any): void {
    this.isRegister = true;
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  public writeValue(value: string): void {
    this.value = value;
  }

  public loadRooms(initial = true): Observable<SelectOption[]> {
    const params = {
      limit: this.pagination.limit,
      offset: this.pagination.offset,
    };

    this.roomLoading = true;

    if (this.roomSearchTerm) {
      set(params, 'search', this.roomSearchTerm);
    }

    return this.roomsService.list(params).pipe(
      filter(res => {
        this.pagination.total = res.total;
        if (initial) {
          return true;
        }
        return this.rooms.length <= this.pagination.total && this.rooms.length !== this.pagination.total;
      }),
      tap(() => {
        this.pagination.offset += this.pagination.limit;
      }),
      map(res => covertRoomToSelectOptions(res.data, this.companyService.companyType)),
      map(rooms => {
        if (initial) {
          this.rooms = rooms;
        } else {
          const allRooms = [...this.rooms, ...rooms];
          this.rooms = this.initialValue ? uniqBy(allRooms, 'value') : allRooms;
        }
        return this.rooms;
      }),
      switchMap(rooms => {
        const containInitialValue = rooms.some(r => r.value === this.initialValue);
        if (initial && this.initialValue && !containInitialValue) {
          return this.roomsService.one(this.initialValue).pipe(
            map((room: IRoom) => covertRoomToSelectOptions([room], this.companyService.companyType)),
            tap(rooms => (this.rooms = [...this.rooms, ...rooms])),
            map(() => this.rooms),
          );
        } else {
          return of(this.rooms);
        }
      }),
      catchError(() => of([])),
      finalize(() => {
        this.roomLoading = false;
        this.cd.detectChanges();
      }),
    );
  }

  public searchInput() {
    return this.roomInput$.pipe(
      tap(res => {
        this.roomSearchTerm = res;
        this.pagination.limit = PAGINATION_LIMIT;
        this.pagination.offset = 0;
      }),
      debounceTime(800),
      switchMap(() => this.loadRooms(true)),
      tap(() => (rooms: SelectOption[]) => (this.rooms = rooms)),
    );
  }

  private validateFn = (_: any) => {
    console.log();
  };
}
