import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonCircleComponent } from '@ui/common/buttons';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'ui-go-back',
  templateUrl: './go-back.component.html',
  styleUrls: ['./go-back.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslateModule, ButtonCircleComponent, RouterLink],
})
export class GoBackComponent {
  @Input() path = '..';
  @Input() text?: string;
  hovering = false;
}
