import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CommonModule } from '@angular/common';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { PoweredByComponent } from './powered-by/powered-by.component';
import { CheckAppsComponent } from './check-apps/check-apps.component';
import { CompaniesListComponent } from './companies-list/companies-list.component';

@Component({
  selector: 'sh-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  standalone: true,
  imports: [CommonModule, NgScrollbarModule, PoweredByComponent, CheckAppsComponent, CompaniesListComponent],
})
export class SidebarComponent implements OnInit {
  poweredBy: string | boolean = environment.poweredBy;
  showAppsBadge = environment?.ui?.showAppsBadge ?? false;
  companySwitcher = environment?.ui?.sidebar?.companySwitcher ?? true;
  isWindows = false;

  constructor(private deviceService: DeviceDetectorService) {}

  ngOnInit() {
    this.isWindows = this.deviceService.getDeviceInfo().os?.toLowerCase() === 'windows';
  }
}
