import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule, SkipSelf, Optional } from '@angular/core';
import { JWT_OPTIONS, JwtModule } from '@auth0/angular-jwt';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { throwIfAlreadyLoaded } from './guards/module-import-guard';
import { ApiPrefixInterceptor } from './http/api-prefix-interceptor';
import { AtV1Interceptor } from './http/at-v1.interceptor';
import { DEFAULT_TIMEOUT, TimeoutInterceptor } from './http/timeout.interceptor';
import { jwtOptionsFactory } from './services/jwt-options.factory';

@NgModule({
  imports: [
    HttpClientModule,
    TranslateModule,
    AngularSvgIconModule,
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useClass: jwtOptionsFactory,
      },
    }),
  ],
  exports: [],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ApiPrefixInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AtV1Interceptor, multi: true },
    { provide: DEFAULT_TIMEOUT, useValue: 45000 },
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
