import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { IJwtTokenResponse } from '@smarttypes/core';
import { BehaviorSubject, map, Observable, of, tap, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { StorageService } from '../../services/storage.service';
import { HttpService } from '../http/http.service';
import { get } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public redirectUrl: string | null = null;

  private isLoggedInSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private tokenChangedSubject$: BehaviorSubject<string | undefined | null> = new BehaviorSubject<
    string | undefined | null
  >(undefined);

  private _refreshToken?: number;

  public get tokenv1(): string | null {
    return StorageService.getItem('sh-t-v1');
  }

  public set tokenv1(value: string | null) {
    if (!value) {
      StorageService.removeItem('sh-t-v1');
    } else {
      StorageService.setItem('sh-t-v1', value);
    }
  }

  public get token(): string | null {
    return StorageService.getItem('sh-t-p');
  }

  public set token(value: string | null) {
    if (!value) {
      StorageService.removeItem('sh-t-p');
      this.tokenChangedSubject$.next(undefined);
    } else {
      StorageService.setItem('sh-t-p', value);
      this.tokenChangedSubject$.next(value);
    }
  }

  public get refreshToken(): string | null {
    return StorageService.getItem('sh-t-r');
  }

  public set refreshToken(value: string | null) {
    if (!value) {
      StorageService.removeItem('sh-t-r');
    } else {
      StorageService.setItem('sh-t-r', value);
    }
  }

  public get isExpired(): boolean | null {
    return this.token ? this.jwtService.isTokenExpired(this.token) : null;
  }

  public get tokenCompanyId(): string | null {
    if (this.token) {
      const decoded = this.jwtService.decodeToken(this.token);
      return get(decoded, 'company', null);
    }
    return null;
  }

  get $loggedIn(): Observable<boolean> {
    return this.isLoggedInSubject$.asObservable();
  }

  get $tokenChanged(): Observable<string | undefined | null> {
    return this.tokenChangedSubject$.asObservable();
  }

  get loggedIn(): boolean {
    return this.isLoggedInSubject$.value;
  }

  constructor(private readonly httpService: HttpService, private readonly jwtService: JwtHelperService) {
    if (this.token) {
      this.isLoggedInSubject$.next(true);
      this.tokenChangedSubject$.next(this.token);
    }
  }

  public logout(force?: boolean) {
    /*if (this.timer$) {
      this.timer$.unsubscribe();
    }*/
    if (force) {
      this.token = null;
      this.refreshToken = null;
      return of(true).pipe(
        tap(() => {
          window.location.assign('/login');
        }),
      );
    }
    return this.httpService.get(`/core/account/logout`).pipe(
      finalize(() => {
        this.token = null;
        this.refreshToken = null;
        window.location.assign('/login');
      }),
    );
  }

  public loginByUsername(username: string, password: string) {
    return (
      this.httpService.post(`/core/account/login-username`, {
        username,
        password,
      }) as Observable<IJwtTokenResponse>
    ).pipe(map((rs: IJwtTokenResponse) => this.setTokens(rs)));
  }

  public loginByPostMessage(rs: IJwtTokenResponse): Observable<IJwtTokenResponse> {
    return of(rs).pipe(map(rs => this.setTokens(rs)));
  }

  public loginByPhone(phone: string, useWhatsApp: boolean, token?: string, code?: string, sendAgain?: boolean) {
    return (
      this.httpService.post(
        `/core/account/login`,
        token && code
          ? {
              phone,
              useWhatsApp,
              token,
              code,
            }
          : { phone, useWhatsApp, sendAgain },
      ) as Observable<IJwtTokenResponse>
    ).pipe(map((rs: IJwtTokenResponse) => this.setTokens(rs)));
  }

  public switch(property: string): Observable<IJwtTokenResponse> {
    return (this.httpService.get(`/core/account/switch/${property}`) as Observable<IJwtTokenResponse>).pipe(
      map(rs => this.setTokens(rs, true)),
    );
  }

  public loginByToken(refreshToken: string) {
    return this.refreshJWTToken(refreshToken);
  }

  public loginByExternal(token: string) {
    const params = new HttpParams({ fromObject: { token } });
    return this.httpService
      .skipRefreshTokenHandler()
      .get<IJwtTokenResponse>(environment.externalLogin as string, {
        params,
      })
      .pipe(map(rs => this.setTokens(rs)));
  }

  public refreshJWTToken(token: string): Observable<IJwtTokenResponse> {
    return (
      this.httpService
        .skipRefreshTokenHandler()
        .get(`/core/account/refresh?token=${token}`) as Observable<IJwtTokenResponse>
    ).pipe(
      map(rs => this.setTokens(rs, true)),
      catchError(err => {
        this.token = null;
        this.refreshToken = null;
        window.location.assign('/login');
        return throwError(() => err);
      }),
    );
  }

  private setTokens(rs: IJwtTokenResponse, refresh = false): IJwtTokenResponse {
    if (rs?.refresh_token) {
      this.refreshToken = rs.refresh_token;
    }
    if (rs?.access_token) {
      this.token = rs.access_token;
      if (!refresh) {
        this.isLoggedInSubject$.next(true);
      }
    }
    if (rs?.access_token_v1) {
      this.tokenv1 = rs.access_token_v1;
    }
    return rs;
  }
}
