import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { AuthService } from './auth.service';
import { filter } from 'rxjs/operators';
import { CompanyService } from './company.service';
import { combineLatest, map, switchMap, take } from 'rxjs';
import { AccountService } from './account.service';
import { environment } from '../../../environments/environment';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';

@Injectable()
export class PushNotificationsService {
  constructor(
    private readonly httpService: HttpService,
    private readonly authService: AuthService,
    private readonly companyService: CompanyService,
    private readonly accountService: AccountService,
    private angularFireMessaging: AngularFireMessaging,
  ) {}

  public init() {
    if (environment.registerPushNotifications) {
      this.authService.$loggedIn
        .pipe(
          filter(e => e),
          switchMap(() => this.angularFireMessaging.requestPermission),
          filter((access: string) => access === 'granted'),
          switchMap(() => this.getFirebaseTokenAndRegister()),
          switchMap(query => this.registerToken(query)),
        )
        .subscribe(_ => {
          // this.angularFireMessaging.messages.subscribe((_messaging: any) => {
          //   _messaging._next = (payload: any) => {
          //   };
          // });
        });
    }
  }

  registerToken(query: { ownerId: unknown; companyId: unknown; token: unknown }) {
    return this.httpService.post('/notifications-manager/register/push', {
      ownerId: query.ownerId,
      token: query.token,
      deviceType: 'web',
    });
  }

  private getFirebaseTokenAndRegister() {
    return combineLatest([
      this.angularFireMessaging.requestToken,
      this.accountService.$currentUser(),
      this.companyService.$currentUser(),
    ]).pipe(
      map(([token, account, company]) => ({
        token,
        ownerId: account._id,
        companyId: company._id,
      })),
      take(1),
    );
  }
}
