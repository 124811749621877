import { parseInt } from 'lodash';
import { environment } from '../../environments/environment';

export class StorageService {
  constructor() {}

  private static getStorage() {
    if (environment.storageType) {
      return environment.storageType;
    }
    return localStorage;
  }

  public static getItem(key: string): string | null {
    const data = this.getStorage().getItem(key);
    if (data && StorageService.hasTTL(data) && !StorageService.extractDataIfValid(data)) {
      StorageService.removeItem(key);
      return null;
    }
    return this.getStorage().getItem(key);
  }

  public static setItem(key: string, value: string, ttl?: number): void {
    if (ttl) {
      StorageService.setTTL(value, ttl);
    }
    return this.getStorage().setItem(key, value);
  }

  public static hasItem(key: string): boolean {
    const data = this.getStorage().getItem(key);

    if (data && StorageService.hasTTL(data) && !StorageService.extractDataIfValid(data)) {
      StorageService.removeItem(key);
      return false;
    }

    return !!data;
  }

  public static removeItem(key: string): void {
    return this.getStorage().removeItem(key);
  }

  public static clear(): void {
    return this.getStorage().clear();
  }

  private static setTTL(value: string, ttl: number): string {
    const seconds = Math.floor(Date.now() / 1000) + ttl;
    value = `${seconds}:TTL:${value}`;
    return value;
  }

  private static hasTTL(value: string): boolean {
    return value.includes(':TTL:');
  }

  private static extractDataIfValid(value: string): string | null {
    const data = value.split(':TTL:');
    if (data.length > 1) {
      const time = parseInt(data[0]);
      const now = Math.floor(Date.now() / 1000);

      if (time < now) {
        return null;
      }
      return data[1];
    }
    return data[0];
  }
}
