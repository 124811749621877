import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';
import { Injectable } from '@angular/core';

@Injectable()
export class CustomMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams): string {
    const paramsInterpolate: { default?: string } = params?.interpolateParams as { default?: string };
    return paramsInterpolate?.default ? paramsInterpolate.default : params.key;
  }
}
