<form [formGroup]="form">
  <div class="checkbox-container" *ngFor="let item of values | slice : 0 : (tagsExpanded ? 999 : 4)">
    <sh-checkbox
      [class.is-locked]="item?.isLocked && item?.disabled"
      [value]="item.value"
      [text]="item.label | translate"
      [id]="item.value"
      [formControlName]="item.value"
    ></sh-checkbox>
    <sh-communication-limit-tip
      *ngIf="item?.isLocked && item.disabled"
      [content]="item?.isLocked"
    ></sh-communication-limit-tip>
  </div>
  <div class="toggle" *ngIf="collapseItems" (click)="toggle()" [ngClass]="{ expanded: tagsExpanded }">
    <svg-icon src="/assets/icons/{{ tagsExpanded ? 'icons-minus' : 'icons-plus' }}.svg"></svg-icon>
    <p>
      {{
        (tagsExpanded ? 'SH.FRONTEND_LIB.CHECKBOX_BUTTONS.LESS' : 'SH.FRONTEND_LIB.CHECKBOX_BUTTONS.MORE') | translate
      }}
    </p>
  </div>
</form>
