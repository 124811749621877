<ui-button-circle icon="icons-close" type="quaternary" [iconSmall]="true" (click)="cancelCrop()"></ui-button-circle>
<tabset [ngClass]="{ 'no-tabs': !library }">
  <tab *ngIf="!noUpload">
    <ng-template tabHeading>
      <svg-icon src="/assets/icons/icons-upload.svg" class="tab-icon"></svg-icon>
      <span>{{ 'SH.FRONTEND_LIB.FILE_LIBRARY.UPLOAD' | translate }}</span>
    </ng-template>
    <div [ngSwitch]="showCropper">
      <div *ngSwitchCase="true">
        <div [ngSwitch]="!!croppedImage">
          <div *ngSwitchCase="true">
            <div class="image-cropped">
              <img [src]="croppedImage" alt="" />
            </div>
            <div class="modal-footer">
              <ui-button-rectangle
                type="primary"
                size="m"
                label="{{ 'SH.SAVE' | translate }}"
                [disabled]="pending"
                [pending]="pending"
                (click)="save()"
              ></ui-button-rectangle>
            </div>
          </div>
          <div *ngSwitchDefault>
            <image-cropper
              [imageFile]="file"
              [maintainAspectRatio]="false"
              format="png"
              [autoCrop]="false"
              (imageCropped)="imageCropped($event)"
            ></image-cropper>
            <div class="modal-footer">
              <ui-button-rectangle
                type="primary"
                size="m"
                label="{{ 'Crop' | translate }}"
                (click)="crop()"
              ></ui-button-rectangle>
            </div>
          </div>
        </div>
      </div>
      <ui-file-drop-down
        *ngSwitchDefault
        (fileChange)="fileChangeEvent($event)"
        [acceptFiles]="acceptFiles"
        [maxFileSize]="maxFileSize"
      ></ui-file-drop-down>
    </div>
  </tab>
  <tab *ngIf="library">
    <ng-template tabHeading>
      <svg-icon src="/assets/icons/icons-library.svg" class="tab-icon"></svg-icon>
      <span>{{ 'SH.FRONTEND_LIB.FILE_LIBRARY.MEDIA_LIBRARY' | translate }}</span>
    </ng-template>
    <div class="library-wrapper">
      <ng-container *ngIf="!loading">
        <ng-container *ngIf="!noFilesAndFolders; else noFilesTemplate">
          <div class="section-header" *ngIf="!selectedFolder && folders.length > 0">
            <h6>{{ 'SH.FRONTEND_LIB.FILE_LIBRARY.FOLDERS' | translate }}</h6>
          </div>
          <div class="library-folders" [ngClass]="{ 'library-folders-sub': selectedFolder }">
            <ng-container *ngIf="folders && !selectedFolder">
              <div
                class="folder-item"
                *ngFor="let catalog of folders"
                (click)="onFolderClick(catalog)"
                [ngxTippy]="shouldDisplayFolderTooltip(catalog) ? folderTemplateTooltip : null"
                [tippyProps]="{
                  duration: [500, 500],
                  offset: [0, 7],
                  delay: [300, 0],
                  animation: 'scale',
                  placement: 'bottom',
                  allowHTML: true
                }"
              >
                <svg-icon src="/assets/icons/icons-folder.svg"></svg-icon>
                <p class="first-letter-capitalize">{{ catalog }}</p>
                <template #folderTemplateTooltip
                ><span class="first-letter-capitalize">{{ catalog }}</span></template
                >
              </div>
            </ng-container>
            <ng-container *ngIf="selectedFolder">
              <div class="back-wrapper" (click)="goBack()">
                <p><-&nbsp;&nbsp;<span class="first-letter-capitalize" [innerHTML]="selectedFolder"></span></p>
              </div>
            </ng-container>
          </div>
        </ng-container>
        <ng-container *ngIf="noFilesInFolder; else filesTemplate">
          <div class="library-empty library-empty-folder">
            <svg-icon class="icon" src="/assets/icons/icons-8-file.svg"></svg-icon>
            <h1>{{ 'SH.FRONTEND_LIB.FILE_LIBRARY.NO_FILES_IN_FOLDER' | translate }}</h1>
          </div>
        </ng-container>
        <ng-template #noFilesTemplate>
          <div class="library-empty">
            <svg-icon class="icon" src="/assets/icons/icons-8-file.svg"></svg-icon>
            <h1>{{ 'SH.FRONTEND_LIB.FILE_LIBRARY.NO_FILES_OR_FOLDER' | translate }}</h1>
            <p>{{ 'SH.FRONTEND_LIB.FILE_LIBRARY.TIP' | translate }}.</p>
          </div>
        </ng-template>
        <ng-template #filesTemplate>
          <div class="section-header" *ngIf="!selectedFolder && folders.length > 0">
            <h6>{{ 'SH.FRONTEND_LIB.FILE_LIBRARY.FILES' | translate }}</h6>
          </div>
          <div class="library-list" *ngIf="mediaList">
            <ui-media-library-item
              *ngFor="let media of mediaList"
              [item]="media"
              [isList]="false"
              (click)="onItemClick(media)"
              [class.selected]="selectedItem === media"
            ></ui-media-library-item>
          </div>
        </ng-template>
      </ng-container>
    </div>
    <div class="modal-footer library-footer" *ngIf="!noUpload">
      <ui-button-rectangle
        type="primary"
        size="m"
        label="{{ 'SH.FRONTEND_LIB.FILE_LIBRARY.CHOOSE' | translate }}"
        (click)="onChooseClick()"
        [disabled]="!selectedItem"
      ></ui-button-rectangle>
    </div>
  </tab>
</tabset>
