import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardComponent } from './card.component';
import { CardTitleComponent } from './card-title/card-title.component';
import { CardBodyComponent } from './card-body/card-body.component';
import { CardHeaderComponent } from './card-header/card-header.component';

@NgModule({
  declarations: [CardComponent, CardTitleComponent, CardBodyComponent, CardHeaderComponent],
  exports: [CardComponent, CardTitleComponent, CardBodyComponent, CardHeaderComponent],
  imports: [CommonModule],
})
export class CardModule {}
