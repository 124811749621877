<ng-select
  class="ng-select-sh"
  [ngClass]="type"
  [class.ng-select-readonly]="readonly"
  [clearable]="clearable"
  [searchable]="searchable"
  [multiple]="multiple"
  [items]="items"
  [bindLabel]="bindLabel"
  [bindValue]="bindValue"
  [formControl]="control"
  [groupBy]="groupBy"
  [trackByFn]="trackByFn"
  [typeToSearchText]="typeToSearchText"
  [typeahead]="typeahead"
  [readonly]="readonly"
  [placeholder]="placeholder"
  [maxSelectedItems]="maxSelectedItems"
  #select
  [addTag]="addTag"
  [minTermLength]="0"
  (scrollToEnd)="onScrollEnd()"
  [loading]="loading"
  [virtualScroll]="virtualScroll"
>
  <ng-template ng-header-tmp *ngIf="searchInList">
    <input
      class="search-input"
      type="search"
      autocomplete="off"
      [placeholder]="typeToSearchText"
      (input)="onFilter($event)"
    />
  </ng-template>
  <ng-template ng-label-tmp let-item="item" let-clear="clear">
    <ng-container *ngIf="icon">
      <svg-icon [src]="iconPath" class="select-icon"></svg-icon>
    </ng-container>
    <ng-container *ngIf="bindLabel && item">
      <svg-icon *ngIf="item.icon" [src]="'/assets/icons/' + item.icon + '.svg'" class="option-icon"></svg-icon>
      <span class="ng-value-label">
        {{ (differentDisplayName ? item[differentDisplayName] ?? item[bindLabel] : item[bindLabel]) | translate }}
      </span>
    </ng-container>
    <ng-container *ngIf="!bindLabel">
      <span class="ng-value-label">{{ item | translate }}</span>
    </ng-container>
    <div class="arrow" *ngIf="arrow && !multiple">
      <svg-icon src="/assets/icons/icons-arrow-down.svg" class="arrow-icon"></svg-icon>
    </div>
    <ng-container *ngIf="multiple">
      <span class="ng-value-clear" (click)="clear(item)" aria-hidden="true">
        <svg-icon src="/assets/icons/icons-close-small.svg" class="arrow-icon"></svg-icon>
      </span>
    </ng-container>
  </ng-template>
  <ng-template ng-option-tmp let-item="item" let-index="index">
    <div class="option" [class.is-locked]="item?.isLocked && item?.disabled">
      <img *ngIf="item?.icon && useImgInsteadSvgIcon" alt="icon" [src]="'/assets/icons/' + item.icon + '.svg'" />
      <svg-icon
        *ngIf="item?.icon && !useImgInsteadSvgIcon"
        [src]="'/assets/icons/' + item.icon + '.svg'"
        class="option-icon"
      ></svg-icon>
      <div class="ng-option-label" *ngIf="bindLabel && item">
        <span class="option-label">{{ item[bindLabel] | translate }}</span>
      </div>
      <div class="ng-option-label" *ngIf="!bindLabel">
        <span>{{ item | translate }}</span>
      </div>
      <svg-icon src="/assets/icons/icons-checkmark.svg" class="ng-option-checkmark"></svg-icon>
      <svg-icon
        *ngIf="item?.emptyRequiredFields"
        src="/assets/icons/icons-warning-filled.svg"
        class="warning-icon"
        ngxTippy="{{ 'SH.MISSING_REQUIRED_CONTENT' | translate }}"
        tippyClassName="tippy-right"
        [tippyProps]="{ duration: [500, 500], offset: [0, 7], delay: [0, 0], animation: 'scale', placement: 'right' }"
      ></svg-icon>
      <div class="clear" *ngIf="clearSelected" (click)="onItemClick(item)"></div>
    </div>
  </ng-template>
  <ng-template ng-tag-tmp let-item="item" let-tag="searchTerm">
    <div class="ng-option-label">
      <span>{{ tag }}</span>
    </div>
    <svg-icon src="/assets/icons/icons-checkmark.svg" class="ng-option-checkmark"></svg-icon>
  </ng-template>
</ng-select>
