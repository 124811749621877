import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AppModules, ICompanyRole } from '@smarttypes/core';
import { ButtonCircleComponent, ButtonRectangleComponent } from '@ui/common/buttons';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { filter, tap } from 'rxjs/operators';
import { distinctUntilKeyChanged, Subscription, switchMap } from 'rxjs';
import { AuthService } from '../../../../core/services/auth.service';
import { CompanyService } from '../../../../core/services/company.service';
import { Router, RouterLinkWithHref } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AvatarComponent } from '@ui/common';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DirectivesModule } from '../../../../directives/directives.module';
import { ngIfDropdownOpenTrigger } from 'angular-v2-animations';

@Component({
  selector: 'sh-companies-list',
  templateUrl: './companies-list.component.html',
  styleUrls: ['./companies-list.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    TranslateModule,
    AngularSvgIconModule,
    ButtonRectangleComponent,
    BsDropdownModule,
    AvatarComponent,
    RouterLinkWithHref,
    DirectivesModule,
    ButtonCircleComponent,
  ],
  animations: [ngIfDropdownOpenTrigger],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompaniesListComponent implements OnInit {
  company!: ICompanyRole;
  companies: ICompanyRole[] = [];
  filteredCompanies: ICompanyRole[] = [];
  protected readonly appFunctionality = AppModules;
  private subscriptions: Subscription = new Subscription();

  constructor(
    private readonly cd: ChangeDetectorRef,
    private readonly authService: AuthService,
    private readonly companyService: CompanyService,
    private readonly router: Router,
    private readonly toastrService: ToastrService,
    private readonly translateService: TranslateService,
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      this.companyService
        .$currentUser()
        .pipe(
          filter(c => !!c),
          distinctUntilKeyChanged('_id'),
          tap(company => (this.company = company)),
          switchMap(() => this.companyService.list()),
          tap(companies => {
            this.companies = companies;
            this.filteredCompanies = companies;
          }),
        )
        .subscribe(() => {
          this.cd.detectChanges();
        }),
    );
  }

  changeCompany(event: MouseEvent, company: ICompanyRole): void {
    event.preventDefault();
    if (company._id) {
      this.toastrService.info(`${this.translateService.instant('SH.SWITCHING_COMPANY')}`);
      this.authService.switch(company._id as string).subscribe(() => {
        window.location.reload();
      });
    }
  }

  onFilterChange(event: Event): void {
    const value = (event?.target as HTMLInputElement)?.value;
    this.filteredCompanies = [...this.companies].filter((company: ICompanyRole) => {
      return company?.name.toLocaleLowerCase().indexOf(value.toLocaleLowerCase()) > -1;
    });
  }

  onFilterInputClick(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }

  onSettings(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.router.navigate(['/settings']);
  }
}
