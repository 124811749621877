import { NgModule } from '@angular/core';
import { BsDropdownConfig, BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { WelcomeModalComponent } from './welcome-modal/welcome-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { FrontendUiModule } from '@smarthotel/angular-ui';
import { FormAvatarComponent } from './form-avatar/form-avatar.component';
import { StatusDotComponent } from './status-dot/status-dot.component';
import { VisitorModalComponent } from './visitor-modal/visitor-modal.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { SendLinkComponent } from './send-link/send-link.component';
import { StayTimelineComponent } from './stay-timeline/stay-timeline.component';
import { PastVisitsComponent } from './past-visits/past-visits.component';
import { NotificationBarComponent } from './notification-bar/notification-bar.component';
import { DirectivesModule } from '../directives/directives.module';
import { FileGroupComponent } from './file-group/file-group.component';
import { FileItemComponent } from './file-item/file-item.component';
import { SortablejsModule } from 'nxt-sortablejs';
import { CheckinFormPreviewModalComponent } from './checkin-form-preview-modal/checkin-form-preview-modal.component';
import { AvatarComponent, BetaBadgeComponent, GrabberComponent, LoaderComponent } from '@ui/common';
import { SelectRoomComponent } from './select-room/select-room.component';
import { VisitorsAccountSelectComponent } from './visitors-account-select/visitors-account-select.component';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { TourStepTemplateComponent } from 'ngx-ui-tour-ngx-bootstrap';
import { MessageComponent } from './message/message.component';
import { MessageSendingStatusComponent } from './message-sending-status/message-sending-status.component';
import { NotesGroupComponent } from './notes-group/notes-group.component';
import { ChatMediaComponent } from './chat-media/chat-media.component';
import { AvatarSelectComponent } from './avatar-select/avatar-select.component';
import { TranslateButtonComponent } from './translate-button/translate-button.component';
import { PaymentsMessageComponent } from './payments-message/payments-message.component';
import { MassMessageSkeletonComponent } from './mass-message/mass-message-skeleton/mass-message-skeleton.component';
import { MassMessageComponent } from './mass-message/mass-message.component';
import { RequestMessageComponent } from '../views/livechat/unified-inbox/chat-view/request-message/request-message.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { GuestRequestComponent } from './guest-request/guest-request.component';
import { ChatTypeIconComponent } from './chat-type-icon/chat-type-icon.component';
import {
  AddReviewComponent,
  AirbnbGuestReviewFormComponent,
  AirbnbHostReviewFormComponent,
  AttachmentComponent,
  BannerComponent,
  FileLibraryDirective,
  InlineToastComponent,
  QrCodeComponent,
  RequestTypeComponent,
  ReviewBcomComponent,
} from '@ui/features';
import { FullNamePipe, LocalizedDatePipe, NlToBrPipe } from 'angular-v2-pipes';
import { PhoneNumberComponent, TagsSelectComponent } from '@ui/common/forms';
import { ButtonCircleComponent, ButtonRectangleComponent, ButtonSquareComponent } from '@ui/common/buttons';
import { FormControlDirective } from 'angular-v2-directives';
import { CreatePaymentComponent } from './create-payment/create-payment.component';
import { PaymentDetailsComponent } from './payment-details/payment-details.component';
import { PaymentsModalSkeletonComponent } from './payment-details/payments-modal-skeleton/payments-modal-skeleton.component';
import { PaymentCancelModalComponent } from './payment-cancel-modal/payment-cancel-modal.component';
import { NgxMaskModule } from 'ngx-mask';
import { LocalizeService } from '../services/localize.service';

@NgModule({
  declarations: [
    WelcomeModalComponent,
    FormAvatarComponent,
    StatusDotComponent,
    VisitorModalComponent,
    SendLinkComponent,
    StayTimelineComponent,
    PastVisitsComponent,
    NotificationBarComponent,
    FileGroupComponent,
    FileItemComponent,
    CheckinFormPreviewModalComponent,
    VisitorsAccountSelectComponent,
    OnboardingComponent,
    MessageComponent,
    MessageSendingStatusComponent,
    NotesGroupComponent,
    ChatMediaComponent,
    AvatarSelectComponent,
    TranslateButtonComponent,
    PaymentsMessageComponent,
    MassMessageComponent,
    MassMessageSkeletonComponent,
    RequestMessageComponent,
    GuestRequestComponent,
    ChatTypeIconComponent,
    CreatePaymentComponent,
    PaymentDetailsComponent,
    PaymentsModalSkeletonComponent,
    PaymentCancelModalComponent,
  ],
  exports: [
    WelcomeModalComponent,
    FormAvatarComponent,
    StatusDotComponent,
    VisitorModalComponent,
    SendLinkComponent,
    StayTimelineComponent,
    PastVisitsComponent,
    NotificationBarComponent,
    FileGroupComponent,
    FileItemComponent,
    CheckinFormPreviewModalComponent,
    VisitorsAccountSelectComponent,
    OnboardingComponent,
    MessageComponent,
    MessageSendingStatusComponent,
    NotesGroupComponent,
    ChatMediaComponent,
    AvatarSelectComponent,
    TranslateButtonComponent,
    PaymentsMessageComponent,
    MassMessageComponent,
    MassMessageSkeletonComponent,
    GuestRequestComponent,
    ChatTypeIconComponent,
    CreatePaymentComponent,
    PaymentDetailsComponent,
    PaymentsModalSkeletonComponent,
    PaymentCancelModalComponent,
  ],
  imports: [
    BsDropdownModule,
    TranslateModule,
    RouterModule,
    CommonModule,
    ModalModule.forRoot(),
    AngularSvgIconModule,
    NgScrollbarModule,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    FrontendUiModule,
    BsDatepickerModule,
    DirectivesModule,
    SortablejsModule,
    BetaBadgeComponent,
    SelectRoomComponent,
    TourStepTemplateComponent,
    NgxSkeletonLoaderModule,
    InlineToastComponent,
    AirbnbGuestReviewFormComponent,
    AirbnbGuestReviewFormComponent,
    AddReviewComponent,
    AirbnbHostReviewFormComponent,
    ReviewBcomComponent,
    NlToBrPipe,
    LoaderComponent,
    PhoneNumberComponent,
    QrCodeComponent,
    FullNamePipe,
    RequestTypeComponent,
    ButtonSquareComponent,
    FormControlDirective,
    TagsSelectComponent,
    AttachmentComponent,
    AvatarComponent,
    GrabberComponent,
    FileLibraryDirective,
    ButtonRectangleComponent,
    NgxMaskModule.forRoot(),
    BannerComponent,
    LocalizedDatePipe,
    ButtonCircleComponent,
  ],
  providers: [
    {
      provide: BsDropdownConfig,
      useValue: { isAnimated: true, autoClose: true },
    },
    LocalizeService,
  ],
})
export class ComponentsModule {
  constructor(private readonly localizeService: LocalizeService) {
    localizeService.init();
  }
}
