import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ITab } from './navigation.widget.interface';

@Component({
  selector: 'ui-navigation',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationComponent implements OnInit {
  @Input() tabs: ITab[] = [];
  @Output() tabClick: EventEmitter<ITab> = new EventEmitter();
  @Input() activeTab?: string | null = null;

  ngOnInit() {
    if (!this.activeTab) {
      this.activeTab = this.tabs ? this.tabs[0].key : '';
    }
  }

  public onTabClick(tab: ITab): void {
    this.tabClick.emit(tab);
  }
}
