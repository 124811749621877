import { utcToZonedTime } from 'date-fns-tz';

export function utcToTimezone(date?: Date, timezone?: string): Date | null {
  if (!date) {
    return null;
  }
  if (timezone) {
    return utcToZonedTime(date, timezone);
  }
  return date;
}
