import { Injectable } from '@angular/core';
import {
  CoverFontEnum,
  IApartmentWifiFromConfig,
  IAttractionsWidget,
  IEventsWidget,
  IGastronomyWidget,
  ILanguage,
  IMediaFile,
  IVaWidget,
  IVaWidgetCategory,
  IVisitorsArea,
  IVisualCover,
} from '@smarttypes/core';
import { get, isObject, set, unset } from 'lodash';
import { BehaviorSubject, debounceTime, Observable, of, Subject } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { HttpService } from '../../core/http/http.service';
import { AccountService } from '../../core/services/account.service';
import { VisitorsCategoryEnum } from './interfaces/visitors-area-form.interface';
import { LanguageService } from '@smarthotel/angular-services';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class VisitorsConfiguratorService {
  public removeWidget$ = new Subject<{ active: boolean; index: number }>();
  public toggleLanguage = new Subject<boolean>();
  public publish$ = new Subject<void>();
  public langs: string[] = [];
  private _config = new BehaviorSubject<IVisitorsArea | null>(null);
  private _language$ = new BehaviorSubject<string>('');
  private config!: IVisitorsArea;
  private lastPublishAt?: Date;

  constructor(
    private readonly httpService: HttpService,
    private readonly languageService: LanguageService,
    private readonly accountService: AccountService,
    private translateService: TranslateService,
  ) {}

  get showUrlFeature(): boolean {
    return environment?.ui?.visitorsAreaConfigurator?.url ?? true;
  }

  get showWorkingHours(): boolean {
    return environment?.ui?.visitorsAreaConfigurator?.workingHours ?? true;
  }

  get showQrCode(): boolean {
    return environment?.ui?.visitorsAreaConfigurator?.workingHours ?? true;
  }

  public get $languageChange(): Observable<string> {
    return this._language$.asObservable();
  }

  public get $widgetRemove(): Observable<{ active: boolean; index: number }> {
    return this.removeWidget$.asObservable();
  }

  public get $publish(): Observable<void> {
    return this.publish$.asObservable();
  }

  public setPublish(): void {
    return this.publish$.next();
  }

  public get placeUrl(): string {
    return `${this._config.value?.subdomain}.${this._config.value?.domain}`;
  }

  public get language(): string {
    return this._language$.value || 'en';
  }

  public get $config(): Observable<IVisitorsArea | null> {
    return this._config.asObservable().pipe(debounceTime(500));
  }

  public get $toggleLanguage(): Observable<boolean> {
    return this.toggleLanguage.asObservable();
  }

  public get lastPublished(): Date {
    return (this.lastPublishAt ?? this._config.getValue()?.lastPublishedAt) as Date;
  }

  public setLanguage(lang: string) {
    this._language$.next(lang);
  }

  public setLanguageVisibility(visible: boolean) {
    this.toggleLanguage.next(visible);
  }

  public getInitialConfig(): Observable<IVisitorsArea> {
    return (this.httpService.request('get', `/visitors-area/configuration`) as Observable<IVisitorsArea>).pipe(
      filter(c => !!c),
      tap(res => (this.config = res)),
      tap(() => {
        if (this.config.languages) {
          this.langs = this.config.languages;
        }
        if (!this.config?.topMessage2) {
          this.setDefaultTopMessage();
        }
      }),
    );
  }

  public saveSubdomain(rq: { subdomain: string }): Observable<void> {
    return this.httpService.request('post', `/visitors-area/configuration/subdomain`, {
      body: rq,
    });
  }

  public publish(draft = false, config?: IVisitorsArea | null): Observable<IVisitorsArea> {
    const data = config ?? (this._config.getValue() as IVisitorsArea);
    const type = draft ? '' : '/publish';

    return this.httpService
      .request('put', `/visitors-area/configuration${type}`, {
        body: data,
      })
      .pipe(tap(() => (this.lastPublishAt = new Date())));
  }

  public getLanguages(availableLanguages: string[]): Observable<ILanguage[]> {
    return this.languageService.getLanguages(environment.apiUrl).pipe(
      map(languages =>
        languages.filter(l => {
          return availableLanguages?.includes(l.iso639_1);
        }),
      ),
    );
  }

  public getWifiContent(): Observable<IApartmentWifiFromConfig[]> {
    return of(this.config).pipe(map((config: IVisitorsArea) => (config.wifi ?? []) as IApartmentWifiFromConfig[]));
  }

  public getWidgetCategory(
    category: VisitorsCategoryEnum = VisitorsCategoryEnum.Info,
  ): Observable<IVaWidgetCategory[]> {
    return of(this._config.value || this.config).pipe(
      map((config: IVisitorsArea) => {
        return config.widgets.filter(widget => widget.category === category) as IVaWidgetCategory[];
      }),
    );
  }

  public getWidgetData<T>(widgetCategory: VisitorsCategoryEnum, widgetCode: string): Observable<T> {
    return this.getWidgetCategory(widgetCategory).pipe(
      map((category: IVaWidgetCategory[]) => {
        return ((category.find(code => code.code === widgetCode) ?? {}) as IVaWidget<T>)?.data as T;
      }),
    );
  }

  public patchObjectFromForm(config: IVisitorsArea, lang: string, form: any, files: IMediaFile[]) {
    const property = get(form, `identification.name`, '');
    const informationWidgets = [...form.widgetsActive, ...form.widgetsArray];
    const subdomain = form.identification?.subdomain?.toLowerCase()?.trim();
    const data: IVisitorsArea = {
      ...config,
      subdomain: subdomain || config?.subdomain?.toLowerCase(),
      name: form.identification?.name,
      accentColor: form.identification?.accentColor,
      topMessage: {
        en: `We are pleased to welcome you at the <strong>${property}</strong>!`,
        pl: `Miło nam Cię gościć w <strong>${property}</strong>!`,
        es: `Nos complace darle la bienvenida en el <strong>${property}</strong>!`,
        de: `Wir freuen uns, Sie im <strong>${property}</strong> begrüßen zu dürfen!`,
        cs: `Jsme rádi, že vás můžeme ubytovat v <strong>${property}</strong>!`,
        hr: `Zadovoljstvo nam je poželjeti vam dobrodošlicu u <strong>${property}</strong>!`,
      },
      coverType: form.identification.coverType,
      brightLogo: form.identification.brightLogo,
      brightLogoOriginal: form.identification.brightLogoOriginal,
      logotype: form.identification.logo,
      logotypeOriginal: form.identification.logotypeOriginal,
      workingHours: form.identification.workingHours,
      url: `${subdomain}.${config.domain}`,
    };

    unset(data, 'wifi');
    set(data, `topMessage2.${lang}`, form?.identification?.topMessage2);

    if (form?.identification?.visualCover?.length) {
      form.identification?.visualCover.forEach((item: IVisualCover, i: number) => {
        set(data, `visualCover[${i}].subtitle`, form?.identification?.visualCover[i]?.subtitle);
        set(data, `visualCover[${i}].title`, form?.identification?.visualCover[i]?.title);
        set(data, `visualCover[${i}].background`, form?.identification?.visualCover[i]?.background);
        set(data, `visualCover[${i}].backgroundOriginal`, form?.identification?.visualCover[i]?.backgroundOriginal);
        set(data, `visualCover[${i}].font`, form?.identification?.visualCover[i]?.font || CoverFontEnum.Default);
      });
      const video = get(config, 'visualCover.0.video', null);
      if (video) {
        set(data, `visualCover.0.video`, video);
      }
    }

    informationWidgets.map((widget, index) => {
      const widgets = data?.widgets as IVaWidgetCategory[];
      if (widget.name !== 'custom') {
        const widgetConfig = widgets.find(w => w.code === widget.name) as any;
        if (!widgetConfig) {
          return;
        }

        set(widgetConfig, 'position', index + 1);
        set(widgetConfig, 'enabled', widget?.active);
        if (!get(widgetConfig, 'data', null)) {
          set(widgetConfig, 'data', widget);
        }

        if (Array.isArray(widgetConfig?.data?.files ?? null)) {
          set(widgetConfig, `data.files`, {});
        }

        if (widget.permalink) {
          set(widgetConfig, `data.permalink`, '');
        }

        for (const [key, value] of Object.entries(get(widgetConfig, 'data', {}))) {
          const newValue = widget[key];

          if (isObject(value) && !this.hasGroupProperty(key)) {
            set(widgetConfig, `data.${key}.${lang}`, newValue);
          } else if (typeof value !== 'object' && (key === 'files' || key === 'description')) {
            set(widgetConfig, `data.${key}.${lang}`, newValue);
          } else {
            set(widgetConfig, `data.${key}`, newValue);
          }
        }
      }
    });

    (data?.widgets as IVaWidgetCategory[])?.sort((a, b) => {
      if (!b?.position) {
        return 1;
      }
      return a?.position > b?.position ? 1 : -1;
    });

    this.setDataToCustomWidget(data.widgets, informationWidgets);

    this.setDataToWidgetSection<IGastronomyWidget[]>(data.widgets, 'gastronomy', lang, form.gastronomy);
    this.setDataToWidgetSection<IEventsWidget[]>(data.widgets, 'events', lang, form.events);
    this.setDataToWidgetSection<IAttractionsWidget[]>(data.widgets, 'attractions', lang, form.attractions);
    this.setDataToWidgetSection<IAttractionsWidget[]>(data.widgets, 'health', lang, form.health);
    this.setDataToWidgetSection<IAttractionsWidget[]>(data.widgets, 'wellness', lang, form.wellness);
    this.setDataToWidgetSection<IAttractionsWidget[]>(data.widgets, 'meals', lang, form.meals);
    this.setDataToWidget(data.widgets, VisitorsCategoryEnum.Faq, 'faq', lang, form.faq || []);
    this.setDataToWidget(data.widgets, VisitorsCategoryEnum.Files, 'files', lang, files || []);

    this.setDataToFlatWidget(data.widgets, VisitorsCategoryEnum.SocialMedia, form.socialMedia || {});

    this.fixFaqEnabledState(data);

    this._config.next(data);
  }

  public updateTitle(code: string, title: string) {
    of(this._config.value)
      .pipe(
        map(config => {
          const conf = Object.assign({}, config);
          const widget = conf?.widgets?.find(w => w.code === code) as IVaWidgetCategory;
          if (!isObject(get(widget, 'data.title', null))) {
            const titlePerLang = this.config?.languages?.reduce((aggr, lang) => {
              return { ...aggr, [lang]: title };
            }, {});
            set(widget, `data.title`, titlePerLang);
          } else {
            set(widget, `data.title.${this.language}`, title);
          }
          return conf;
        }),
      )
      .subscribe(config => this._config.next(config));
  }

  public removeItemFromOtherLanguages(widgetName: string, idx: number): void {
    if (widgetName === 'faq') {
      const widget = this.config.widgets.find(widget => widget.code === 'faq');
      const faq = get(widget, 'data.faq');

      for (const item of Object.entries(faq as any) as any) {
        delete get(faq, item[0])[idx];
      }
    }
  }

  public translateDay(day: string) {
    return this.translateService.instant(`SH.${day.toUpperCase()}`);
  }

  private setDataToCustomWidget(widgets: IVaWidgetCategory[], form: any) {
    const customWidget = widgets.find(w => w.code === 'custom-widget-v1') as any;
    const customWidgetsData = get(customWidget, 'data.widgets', []);
    const data = form?.filter((w: any) => w.name === 'custom');

    set(customWidget, 'data.widgets', data);
    set(customWidget, 'enabled', (customWidgetsData?.length ?? 0) > 0);
  }

  private fixFaqEnabledState(data: IVisitorsArea) {
    return data.widgets.map(item => {
      if (item.code === 'faq') {
        set(
          item,
          'enabled',
          Object.values(get(item, `data.faq`, {})).some(a => ((a as [])?.length ?? 0) > 0),
        );
      }
      return item;
    });
  }

  private setDataToWidget(
    widgets: IVaWidgetCategory[],
    category: VisitorsCategoryEnum,
    key: string,
    language: string,
    data: unknown,
  ) {
    return widgets.map(w => {
      if (w.category === category) {
        set(w, `enabled`, Array.isArray(data) && data.length > 0);
        set(w, `data.${key}.${language}`, data);
      }
      return w;
    });
  }

  private setDataToWidgetSection<T>(widgets: any, code: string, language: string, form: any) {
    // @TODO: THIS PART NEEDS A REFACTOR
    const section = widgets?.find((w: IVaWidget) => w.code === code) as IVaWidget<T[]>;

    if (!section) {
      return;
    }

    section.enabled =
      form?.length > 0 &&
      form?.filter((widget: any) => get(widget, `active.${language}`, widget?.active ?? false) === true)?.length > 0;

    const objs = (form as any[]).sort((a, b) => (a?.position > b?.position ? 1 : -1));

    set(section, 'data.objects', objs);
  }

  private setDefaultTopMessage() {
    set(this.config, 'topMessage2', {
      en: `Below you will find the most important information about the functioning of our hotel and your stay:`,
      pl: `Poniżej znajdziesz najważniejsze informacje o funkcjonowaniu naszego hotelu i Twojego pobytu:`,
      es: `A continuación encontrará la información más importante sobre el funcionamiento de nuestro hotel y su estancia:`,
      de: `Nachfolgend finden Sie die wichtigsten Informationen über die Funktionsweise unseres Hotels und Ihren Aufenthalt:`,
      cs: `Níže najdete nejdůležitější informace týkající se fungování našeho hotelu a vašeho pobytu:`,
    });
  }

  private setDataToFlatWidget(widgets: IVaWidgetCategory[], category: VisitorsCategoryEnum, data: unknown) {
    return widgets.map(w => {
      if (w.category === category && !Array.isArray(data)) {
        set(w, 'data', data);

        if (category === VisitorsCategoryEnum.SocialMedia) {
          set(
            w,
            'enabled',
            Object.entries(data as [string, string]).some(obj => obj[1]?.split('.com/')[1]?.length > 0),
          );
        } else {
          set(
            w,
            'enabled',
            Object.entries(data as [string, string]).some(obj => obj[1]?.length > 0),
          );
        }
      }
      return w;
    });
  }

  private hasGroupProperty(key: string): boolean {
    return key === 'days' || key === 'channels';
  }

  clearValidatorsInsideFormGroup(fg: FormGroup) {
    return Object.values(fg.controls).forEach(control => {
      control.clearValidators();
      control.updateValueAndValidity({ emitEvent: false });
    });
  }
}
