import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularSvgIconModule, SvgIconComponent, SvgLoader } from 'angular-svg-icon';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { MissingTranslationHandler, TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { SelectCountryComponent } from './components/select-country/select-country.component';
import { ButtonCircleComponent } from './components/button-circle/button-circle.component';
import { ButtonRectangleComponent } from './components/button-rectangle/button-rectangle.component';
import { ErrorMessageComponent } from './components/error-messages/error-message.component';
import { SelectComponent } from './components/select/select.component';
import { SwitchComponent } from './components/switch/switch.component';
import { FormComponent } from './components/form.component';
import { InputModalComponent } from './components/input-modal/input-modal.component';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { ButtonTertiaryComponent } from './components/button-tertiary/button-tertiary.component';
import { RadioButtonsComponent } from './components/radio-buttons/radio-buttons.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { CheckComponent } from './components/checkbox/check/check.component';
import { CheckboxButtonsComponent } from './components/checkbox-buttons/checkbox-buttons.component';
import { CopyTextComponent } from './components/copy-text/copy-text.component';
import { UploaderComponent } from './components/uploader/uploader.component';
import { CoverImageComponent } from './components/cover-image/cover-image.component';
import { ModalBaseComponent } from './components/modal-base/modal-base.component';
import { NgxMaskModule } from 'ngx-mask';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { CheckinFormComponent } from './components/checkin-form/checkin-form.component';
import { CheckinFormQuestionComponent } from './components/checkin-form/checkin-form-question/checkin-form-question.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { AngularSignaturePadModule } from '@almothafar/angular-signature-pad';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CheckinSignatureModalComponent } from './components/checkin-form/checkin-signature-modal/checkin-signature-modal.component';
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';
import { CommunicationLimitTipComponent } from './components/communication-limit-tip/communication-limit-tip.component';
import { StayTimelineComponent } from './components/stay-timeline/stay-timeline.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { AvatarComponent, FlagIconComponent, LoaderComponent, NavigationComponent } from '@ui/common';
import { AddRoommateComponent, AttachmentComponent, RoomBadgeComponent } from '@ui/features';
import {
  ErrorMessageComponent as ErrorMessageComponentUI,
  FormComponent as FormComponentUI,
  PhoneNumberComponent,
  SelectComponent as SelectComponentUI,
  SelectCountryComponent as SelectCountryComponentUI,
} from '@ui/common/forms';
import { LinkProtocolPipe, LocalizedDatePipe, MaxCharsPipe, NlToBrPipe } from 'angular-v2-pipes';
import { CustomMissingTranslationHandler, SvgBrowserLoader } from 'angular-v2-utils';
import { DragNDropDirective, FormControlDirective } from 'angular-v2-directives';
import { ButtonPillComponent, ButtonRectangleComponent as ButtonRectangleComponentUI } from '@ui/common/buttons';

function provideSvgLoader(http: HttpClient) {
  return new SvgBrowserLoader(http);
}

@NgModule({
  declarations: [
    ButtonCircleComponent,
    ButtonRectangleComponent,
    ErrorMessageComponent,
    SelectComponent,
    SelectCountryComponent,
    ButtonRectangleComponent,
    SwitchComponent,
    FormComponent,
    InputModalComponent,
    ConfirmModalComponent,
    ButtonTertiaryComponent,
    CheckboxComponent,
    CheckComponent,
    RadioButtonsComponent,
    CheckboxComponent,
    CheckComponent,
    CheckboxButtonsComponent,
    CopyTextComponent,
    CoverImageComponent,
    ModalBaseComponent,
    UploaderComponent,
    CheckinFormComponent,
    CheckinFormQuestionComponent,
    CheckinSignatureModalComponent,
    FileUploaderComponent,
    CheckinSignatureModalComponent,
    CommunicationLimitTipComponent,
    StayTimelineComponent,
    TabsComponent,
  ],
  imports: [
    FormsModule,
    NgSelectModule,
    HttpClientModule,
    ReactiveFormsModule,
    TranslateModule,
    AngularSvgIconModule.forRoot({
      loader: {
        provide: SvgLoader,
        useFactory: provideSvgLoader,
        deps: [HttpClient],
      },
    }),
    CommonModule,
    NgxTippyModule,
    NgxMaskModule.forRoot(),
    NgxSkeletonLoaderModule,
    BsDatepickerModule,
    AngularSignaturePadModule,
    ModalModule.forRoot(),
    NavigationComponent,
    AddRoommateComponent,
    SelectComponentUI,
    MaxCharsPipe,
    LocalizedDatePipe,
    NlToBrPipe,
    LinkProtocolPipe,
    PhoneNumberComponent,
    LoaderComponent,
    DragNDropDirective,
    AvatarComponent,
    FormControlDirective,
    AttachmentComponent,
    FlagIconComponent,
    FormComponentUI,
    ErrorMessageComponentUI,
    SelectCountryComponentUI,
    ButtonPillComponent,
    ButtonRectangleComponentUI,
    RoomBadgeComponent,
  ],
  exports: [
    NgxTippyModule,
    SvgIconComponent,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    ButtonCircleComponent,
    ButtonRectangleComponent,
    ErrorMessageComponent,
    SelectComponent,
    SelectCountryComponent,
    ButtonRectangleComponent,
    SwitchComponent,
    FormComponent,
    InputModalComponent,
    ConfirmModalComponent,
    ButtonTertiaryComponent,
    CheckboxComponent,
    CheckComponent,
    RadioButtonsComponent,
    CheckboxComponent,
    CheckComponent,
    CheckboxButtonsComponent,
    CopyTextComponent,
    UploaderComponent,
    CoverImageComponent,
    ModalBaseComponent,
    CoverImageComponent,
    CheckinFormComponent,
    StayTimelineComponent,
    TabsComponent,
  ],
  providers: [
    {
      provide: MissingTranslationHandler,
      useClass: CustomMissingTranslationHandler,
    },
  ],
})
export class FrontendUiModule {}
