import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FileGroupComponent } from './file-group/file-group.component';
import { FileItemComponent } from './file-item/file-item.component';
import { SortablejsModule } from 'nxt-sortablejs';
import { FrontendUiModule } from '@smarthotel/angular-ui';
import { FormSectionComponent } from './form-section/form-section.component';
import { FormFieldLanguagesHintComponent } from './form-field-languages-hint/form-field-languages-hint.component';
import { FormFieldHintComponent } from './form-field-hint/form-field-hint.component';
import { ButtonSquareComponent } from '@ui/common/buttons';
import { FlagIconComponent, GrabberComponent } from '@ui/common';
import { FileLibraryDirective } from '@ui/features';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    SortablejsModule,
    FrontendUiModule,
    ButtonSquareComponent,
    GrabberComponent,
    FlagIconComponent,
    FileLibraryDirective,
  ],
  declarations: [
    FileGroupComponent,
    FileItemComponent,
    FormSectionComponent,
    FormFieldLanguagesHintComponent,
    FormFieldHintComponent,
  ],
  exports: [
    FileGroupComponent,
    FileItemComponent,
    FormSectionComponent,
    FormFieldLanguagesHintComponent,
    FormFieldHintComponent,
  ],
})
export class ConfiguratorComponentsModule {}
