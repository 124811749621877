import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { IAirbnbHostReview, IMessageItem } from '@smarttypes/messages';
import { IGuest } from '@smarttypes/hotel';
import { ApiValidatorService } from 'angular-v2-services';
import { ConfirmModalComponent } from '@ui/common/modals';
import { CheckboxComponent, ErrorMessageComponent, FormComponent } from '@ui/common/forms';
import { ButtonRectangleComponent } from '@ui/common/buttons';
import { CommonModule } from '@angular/common';
import { ReviewStarsComponent } from '../review-stars/review-stars.component';
import { FormControlDirective } from 'angular-v2-directives';

@Component({
  selector: 'ui-add-review',
  standalone: true,
  templateUrl: './add-review.component.html',
  styleUrls: ['./add-review.component.scss'],
  imports: [
    CommonModule,
    ErrorMessageComponent,
    TranslateModule,
    FormComponent,
    CheckboxComponent,
    ButtonRectangleComponent,
    ReactiveFormsModule,
    ReviewStarsComponent,
    FormControlDirective,
  ],
})
export class AddReviewComponent implements OnChanges {
  @Input() message?: IMessageItem;
  @Input() guest?: IGuest;
  @Output() public review: EventEmitter<IAirbnbHostReview> = new EventEmitter<IAirbnbHostReview>();
  public pending = false;
  public form: FormGroup = new FormGroup({
    IsReviewRecommended: new FormControl(true),
    Review: new FormControl('', [Validators.required]),
    CategoryRatings: new FormGroup({
      cleanliness: new FormControl(null, [Validators.required]),
      communication: new FormControl(null, [Validators.required]),
      hoursRule: new FormControl(null, [Validators.required]),
    }),
  });

  constructor(
    private apiValidator: ApiValidatorService,
    private readonly modalService: BsModalService,
    private readonly cd: ChangeDetectorRef,
    private translateService: TranslateService,
  ) {}

  public get guestName(): string {
    return this.guest?.person?.firstName ?? '';
  }

  public get disabled(): boolean {
    return !this.form.valid || this.pending;
  }

  public get isReviewSubmited() {
    return (this.message?.data as any)?.Message !== '';
  }

  public get isExpired(): boolean {
    return (this.message?.data as any)?.isExpired ?? false;
  }

  public get isPublished(): boolean {
    return (this.message?.data as any)?.IsPublished;
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['message']?.currentValue) {
      this.message = changes['message'].currentValue;
    }
    if (changes['guest']?.currentValue) {
      this.guest = changes['guest'].currentValue;
    }
  }

  public onSubmit(): void {
    if (this.pending || !this.apiValidator.formIsValid(this.form)) {
      return;
    }
    this.pending = true;
    this.modalService.show(ConfirmModalComponent, {
      ignoreBackdropClick: true,
      keyboard: false,
      initialState: {
        confirmFunc: (): void => {
          this.pending = false;
          this.review.emit(this.form.value as IAirbnbHostReview);
        },
        cancelFunc: (): void => {
          this.pending = false;
          this.cd.detectChanges();
        },
        text: {
          header: this.translateService.instant('SH.CHAT.REVIEW_MODAL.HEADER'),
          body: this.translateService.instant('SH.CHAT.REVIEW_MODAL.BODY'),
          submit: this.translateService.instant('SH.CHAT.REVIEW_MODAL.YES'),
          cancel: this.translateService.instant('SH.CHAT.REVIEW_MODAL.NO'),
        },
      },
    });
  }
}
