import {
  Component,
  EventEmitter,
  forwardRef,
  HostBinding,
  Injector,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  ControlContainer,
  ControlValueAccessor,
  FormControl,
  FormControlDirective,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { NgSelectComponent } from '@ng-select/ng-select';
import { get } from 'lodash';
import { NgxTippyProps } from 'ngx-tippy-wrapper';

@Component({
  selector: 'sh-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
})
export class SelectComponent implements ControlValueAccessor, OnChanges {
  @Input() items: any[] = [];
  @Input() bindValue!: string;
  @Input() bindLabel!: string;
  @Input() clearable = false;
  @Input() multiple = false;
  @Input() trackByFn!: () => void;
  @Input() change!: () => void;
  @Input() loading = false;
  @Input() searchable = false;
  @Input() searchInList = false;
  @Input() typeToSearchText = '';
  @Input() typeahead!: Subject<string>;
  @Input() groupBy = '';
  @Input() placeholder!: string;
  @Input() maxSelectedItems!: number;
  @Input() formControl!: FormControl;
  @Input() formControlName!: string;
  @Input() readonly = false;
  @Input() icon = '';
  @Input() type = 'ng-select-pill';
  @Input() arrow = true;
  @HostBinding('class.disabled') @Input() disabled = false;
  @HostBinding('class.white') @Input() white = false;
  @Input() locked = false;
  @Input() addTag: boolean | ((term: string) => any | Promise<any>) = false;
  @Input() clearSelected = true;
  @Input() minTermLength = 3;
  @Input() differentDisplayName: string | undefined;
  @Input() useImgInsteadSvgIcon = false;
  @ViewChild(FormControlDirective, { static: true })
  formControlDirective!: FormControlDirective;
  @ViewChild('select', { static: true }) select!: NgSelectComponent;
  @Output() public loadMore: EventEmitter<void> = new EventEmitter();
  private isRegister = false;
  public tooltipSettings: NgxTippyProps = {
    duration: [500, 500],
    offset: [0, 8],
    animation: 'scale',
    placement: 'bottom-start',
    theme: 'light',
  };

  constructor(private injector: Injector) {}

  get control() {
    return this.formControl || this.controlContainer.control?.get(this.formControlName);
  }

  get iconPath(): string {
    if (this.icon) {
      return `/assets/icons/${this.icon}.svg`;
    }
    return '';
  }

  get controlContainer() {
    return this.injector.get(ControlContainer);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['locked']) {
      this.setDisabledState(changes['locked']?.currentValue);
    }
  }

  onItemClick(item: string | unknown) {
    if (item === this.formControlDirective.value || get(item, this.bindValue, '') === this.formControlDirective.value) {
      setTimeout(() => {
        this.select.handleClearClick();
      }, 100);
    }
  }

  registerOnTouched(fn: any): void {
    this.formControlDirective.valueAccessor?.registerOnTouched(fn);
  }

  registerOnChange(fn: any): void {
    this.isRegister = true;
    this.formControlDirective.valueAccessor?.registerOnChange(fn);
  }

  writeValue(obj: any): void {
    if (this.isRegister) {
      this.formControlDirective.valueAccessor?.writeValue(obj);
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.formControlDirective?.valueAccessor?.setDisabledState?.(isDisabled);
    this.disabled = isDisabled;
  }

  onScroll() {
    this.loadMore.emit();
  }
}
