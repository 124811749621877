import { Component, Input } from '@angular/core';
import { IAirbnbHostReview, IMessageItem } from '@smarttypes/messages';
import { TranslateModule } from '@ngx-translate/core';
import { ReviewStarsComponent } from '../review-stars/review-stars.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'ui-airbnb-host-review-form',
  standalone: true,
  templateUrl: './airbnb-host-review-form.component.html',
  styleUrls: ['./airbnb-host-review-form.component.scss'],
  imports: [CommonModule, TranslateModule, ReviewStarsComponent],
})
export class AirbnbHostReviewFormComponent {
  @Input() message?: IMessageItem;

  get rating(): IAirbnbHostReview | undefined {
    return (this.message?.data as any)?.Message as IAirbnbHostReview | undefined;
  }
}
