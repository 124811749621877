import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { IGuest } from '@smarttypes/hotel';
import { ConfirmModalComponent } from '@ui/common/modals';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'ui-approval-box',
  standalone: true,
  templateUrl: './approval-box.component.html',
  imports: [CommonModule, TranslateModule],
  styleUrls: ['./approval-box.component.scss'],
})
export class ApprovalBoxComponent implements OnChanges {
  @Input() public guest?: IGuest;
  @Input() public processing = false;
  @Output() public action: EventEmitter<void> = new EventEmitter<void>();
  private approvalConfig?: { pending?: boolean };

  constructor(
    private readonly modalService: BsModalService,
    private readonly translateService: TranslateService,
    private readonly cd: ChangeDetectorRef,
  ) {}

  public get isApproved(): boolean {
    return this.approvalConfig?.pending ?? false;
  }

  public get guestFullName(): string {
    return `${this?.guest?.person?.firstName} ${this?.guest?.person?.lastName}`;
  }

  public buttonClick(): void {
    if (this.processing) {
      return;
    }
    this.modalService.show(ConfirmModalComponent, {
      ignoreBackdropClick: true,
      keyboard: false,
      initialState: {
        confirmFunc: (): void => {
          this.processing = true;
          this.cd.detectChanges();
          this.action.emit();
        },
        text: {
          header: this.translateService.instant(
            `SH.CHAT.${!this.isApproved ? 'APPROVAL_MODAL' : 'APPROVAL_R_MODAL'}.HEADER`,
          ),
          body: this.translateService.instant(
            `SH.CHAT.${!this.isApproved ? 'APPROVAL_MODAL' : 'APPROVAL_R_MODAL'}.BODY`,
          ),
          submit: this.translateService.instant(
            `SH.CHAT.${!this.isApproved ? 'APPROVAL_MODAL' : 'APPROVAL_R_MODAL'}.YES`,
          ),
          cancel: this.translateService.instant(
            `SH.CHAT.${!this.isApproved ? 'APPROVAL_MODAL' : 'APPROVAL_R_MODAL'}.NO`,
          ),
        },
      },
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['processing']) {
      this.processing = changes['processing'].currentValue;
      this.cd.detectChanges();
    }
    if (changes['guest']) {
      this.guest = changes['guest'].currentValue;
      this.approvalConfig = (this.guest?.config as { approval?: { pending?: boolean } })?.approval;
      this.cd.detectChanges();
    }
  }
}
