import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VisitorsPreviewComponent } from './visitors-preview.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { QRCodeModule } from 'angularx-qrcode';
import { DirectivesModule } from '../../../directives/directives.module';

@NgModule({
  declarations: [VisitorsPreviewComponent],
  imports: [CommonModule, RouterModule, QRCodeModule, TranslateModule, DirectivesModule],
  exports: [VisitorsPreviewComponent],
})
export class VisitorsPreviewModule {}
