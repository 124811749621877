<div class="d-flex align-items-center justify-content-between">
  <div class="page-header">
    <h1>{{ name }}</h1>
    <ui-beta-badge *ngIf="beta"></ui-beta-badge>
  </div>
  <div class="page-actions">
    <ng-content></ng-content>
  </div>
</div>
<p *ngIf="description"><span [innerHTML]="description"></span></p>
