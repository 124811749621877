import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ButtonRectangleComponent } from '@ui/common/buttons';
import { TipComponent } from '@ui/common';
import { IMessageStats } from '@smarttypes/metrics';
import { CommunicationChannelEnum } from '@smarttypes/core';

@Component({
  selector: 'ui-messages-sent-by-channel-item',
  templateUrl: './messages-sent-by-channel-item.component.html',
  styleUrls: ['./messages-sent-by-channel-item.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, AngularSvgIconModule, ButtonRectangleComponent, TipComponent],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessagesSentByChannelItemComponent {
  @Input() stats?: IMessageStats;

  get isSms(): boolean {
    return this.stats?.channel === CommunicationChannelEnum.Sms;
  }

  get name(): string {
    return `SH.${this.stats?.channel.toUpperCase()}`;
  }

  get change(): string | number {
    if ((this.stats?.change || 0) > 0) {
      return `+${Math.round(this.stats?.change || 0)}`;
    }

    return Math.round(this.stats?.change || 0);
  }

  get icon(): string {
    switch (this.stats?.channel) {
      case CommunicationChannelEnum.Sms:
        return '/assets/icons/icons-sms-chat.svg';
      case CommunicationChannelEnum.WhatsApp:
        return '/assets/icons/icons-whatsapp-chat.svg';
      case CommunicationChannelEnum.Email:
        return '/assets/icons/icons-email-chat.svg';
      default:
        return '';
    }
  }
}
