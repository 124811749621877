<div class="d-flex justify-content-end align-items-center" *ngIf="user">
  <div *ngIf="isServiceAccount" class="service-account-badge">
    <svg-icon src="/assets/icons/icons-color-services.svg"></svg-icon>
    <span>{{ 'SH.SERVICE_ACCOUNT' | translate }}</span>
  </div>
  <div dropdown class="btn-group" [isAnimated]="false">
    <div dropdownToggle class="dropdown-button">
      <ui-avatar size="l" class="avatar" [object]="displayName" [rounded]="true" type="initialsUser"></ui-avatar>
      <svg-icon src="/assets/icons/icons-arrow-short-down-s.svg" class="arrow"></svg-icon>
    </div>
    <ul *dropdownMenu [@ngIfDropdownOpen] class="dropdown-menu">
      <li *ngIf="!isServiceAccount">
        <a class="dropdown-item" routerLink="/account">{{ 'SH.USER.PERSONAL_ACCOUNT' | translate }}</a>
      </li>
      <li>
        <a class="dropdown-item logout" (click)="onLogout()">{{ 'SH.USER.LOGOUT' | translate }}</a>
      </li>
    </ul>
  </div>
</div>
