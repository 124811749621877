import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { map, take } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.auth.$loggedIn.pipe(
      take(1),
      map(e => {
        if (e) {
          return true;
        } else {
          this.auth.redirectUrl = state.url;
          const token = route.queryParamMap.get('token');
          if (token) {
            this.router.navigate(['/login'], {
              queryParams: {
                token,
              },
            });
          } else {
            this.router.navigate(['/login']);
          }

          return false;
        }
      }),
    );
  }
}
