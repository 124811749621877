import { Component } from '@angular/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'ui-mobile-device-message',
  templateUrl: './mobile-device-message.component.html',
  styleUrls: ['./mobile-device-message.component.scss'],
  standalone: true,
  imports: [AngularSvgIconModule, TranslateModule],
})
export class MobileDeviceMessageComponent {}
