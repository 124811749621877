import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ButtonRectangleComponent } from '@ui/common/buttons';
import { FormComponent } from '@ui/common/forms';
import { FormControlDirective } from 'angular-v2-directives';

export interface InputConfirmModelText {
  header?: any;
  body?: any;
  submit?: any;
  submit2?: any;
  cancel?: any;
  image?: any;
  btnClass?: any;
}

@Component({
  selector: 'ui-input-modal',
  templateUrl: './input-modal.component.html',
  styleUrls: ['./input-modal.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, FormsModule, FormControlDirective, ButtonRectangleComponent, FormComponent],
})
export class InputModalComponent implements OnInit, AfterViewInit {
  @Input() public value?: string;
  @Input() public text?: InputConfirmModelText;
  @Input() public selectValue?: boolean;
  @Input() public canBeLeftEmpty = true;
  @Input() confirmFunc?: () => void;
  @ViewChild('input') public input?: ElementRef<HTMLInputElement>;
  @Output() public save: EventEmitter<string> = new EventEmitter<string>();
  public cfg?: InputConfirmModelText;
  private cfgDefault: InputConfirmModelText = {
    header: 'HEADER',
    submit: 'SUBMIT 1',
    submit2: 'SUBMIT 2',
  };

  constructor(public bsModalRef: BsModalRef) {}

  get hasError(): boolean {
    return (this.value?.length ?? 0) < 1 && !this.canBeLeftEmpty;
  }

  ngOnInit() {
    this.cfg = Object.assign({}, this.cfgDefault, this.text);
  }

  onConfirmClick() {
    if ((this.value?.trim()?.length ?? 0) < 1) {
      return;
    }
    this.save.emit(this.value);
    this.bsModalRef.hide();
  }

  onCancelClick() {
    this.bsModalRef.hide();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.input) {
        this.input?.nativeElement.focus();
        if (this?.selectValue) {
          this.input?.nativeElement.select();
        }
      }
    }, 500);
  }
}
