import { NgModule } from '@angular/core';
import { VisitorsConfiguratorComponent } from './visitors-configurator.component';
import { VisitorsPreviewModule } from './visitors-preview/visitors-preview.module';
import { VisitorsFormModule } from './visitors-form/visitors-form.module';
import { ToastrModule } from 'ngx-toastr';
import { TranslateModule } from '@ngx-translate/core';
import { FrontendUiModule } from '@smarthotel/angular-ui';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DirectivesModule } from '../../directives/directives.module';
import { PageTitleComponent } from '@ui/common';
import { InlineToastComponent } from '@ui/features';
import { VisitorsApartmentModule } from './visitors-apartment/visitors-apartment.module';

@NgModule({
  declarations: [VisitorsConfiguratorComponent],
  imports: [
    CommonModule,
    VisitorsFormModule,
    VisitorsPreviewModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-left',
      closeButton: true,
    }),
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    FrontendUiModule,
    DirectivesModule,
    PageTitleComponent,
    InlineToastComponent,
    VisitorsApartmentModule,
  ],
  exports: [VisitorsConfiguratorComponent],
})
export class VisitorsConfiguratorModule {}
