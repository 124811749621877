import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  CheckboxComponent,
  ErrorMessageComponent,
  FormComponent,
  MasterButtonsComponent,
  SelectComponent,
  SwitchComponent,
} from '@ui/common/forms';
import { FormGroup, FormsModule, ReactiveFormsModule, ValidatorFn, Validators } from '@angular/forms';
import { Subscription, tap } from 'rxjs';
import { ApiValidatorService } from 'angular-v2-services';
import { FormControlDirective } from 'angular-v2-directives';
import { generateTimeArray } from 'angular-v2-utils';

@Component({
  selector: 'wg-form-hours',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ErrorMessageComponent,
    ReactiveFormsModule,
    SelectComponent,
    CheckboxComponent,
    FormComponent,
    FormControlDirective,
    SwitchComponent,
    MasterButtonsComponent,
  ],
  templateUrl: './form-hours.component.html',
  styleUrls: ['./form-hours.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class FormHoursComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public yesNo: { label: string; value: boolean }[] = [];
  @Input() public showHeader = false;
  @Input() public header = 'SH.VISITORS_PLACE.OPENING_HOURS';
  @Input() public active = false;
  @Input() public useAMPM = false;
  @Input() public additionalDesc = true;
  @Input() public form = new FormGroup({});
  public hours: { label: string; value: string }[] = [];

  public days: string[] = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
  public week: string[] = ['workDays', 'weekDays'];
  private subscriptions: Subscription = new Subscription();

  constructor(
    private readonly _cd: ChangeDetectorRef,
    private translateService: TranslateService,
    private apiValidator: ApiValidatorService,
  ) {}

  public get fg(): FormGroup {
    return this.form as unknown as FormGroup;
  }

  public get eachDayEnabled(): boolean {
    return this.fg?.get('eachDay')?.value ?? false;
  }

  public ngOnInit(): void {
    this.hours = generateTimeArray(30, this.useAMPM);
    this.updateWeekValidity();
    this.days.forEach(day => {
      this.updateDayValidator(day);
    });

    this.subscriptions.add(
      this.fg
        ?.get('active')
        ?.valueChanges.pipe(
          tap(active => {
            if (this.showHeader) {
              this.toggleForm(active);
            }
            this.updateWeekValidity();
            this.days.forEach(day => {
              this.updateDayValidator(day);
            });
          }),
        )
        .subscribe(() => {
          this._cd.detectChanges();
        }),
    );

    this.subscriptions.add(
      this.fg
        ?.get('eachDay')
        ?.valueChanges.pipe(
          tap(active => {
            this.toggleForm(!active, this.week);
            this.updateWeekValidity();
            this.days.forEach(day => {
              this.updateDayValidator(day);
            });
          }),
        )
        .subscribe(() => {
          this._cd.detectChanges();
        }),
    );

    this.subscriptions.add(
      this.fg
        ?.get('is24h')
        ?.valueChanges.pipe(
          tap(() => {
            this.updateWeekValidity();
            this.days.forEach(day => {
              this.updateDayValidator(day);
            });
          }),
        )
        .subscribe(() => {
          this._cd.detectChanges();
        }),
    );

    this.eachDayValidation();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['active'].currentValue !== changes['active'].previousValue) {
      this.updateWeekValidity();
      this.days.forEach(day => {
        this.updateDayValidator(day);
      });
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public dayEnabled(day: string): boolean {
    return this.fg?.get(day)?.get('isOpen')?.value ?? false;
  }

  public translateDay(day: string): string {
    return this.translateService.instant(`SH.${day.toUpperCase()}`);
  }

  private toggleForm(active: boolean, controls?: string[]): void {
    const c = controls ?? Object.keys(this.fg?.controls);
    if (active) {
      this.fg.enable({
        emitEvent: false,
      });
    } else {
      c.filter(c => c !== 'active').forEach(() => {
        // this.fg?.get(c)?.disable({
        //   emitEvent: false,
        // });
      });
    }
  }

  private eachDayValidation() {
    this.days.forEach(day => {
      this.subscriptions.add(
        this.fg
          .get(day)
          ?.get('isOpen')
          ?.valueChanges.subscribe(() => {
            this.updateDayValidator(day);
          }),
      );
    });
  }

  private updateWeekValidity() {
    const validators: ValidatorFn[] = [this.apiValidator.validateTime];

    if (this.active && this.fg.get('active')?.value && !this.eachDayEnabled && !this.fg?.get('is24h')?.value) {
      validators.push(Validators.required);
    }

    this.week.forEach((key: string) => {
      this.fg.get(key)?.get('from')?.setValidators(validators);
      this.fg.get(key)?.get('from')?.updateValueAndValidity({
        emitEvent: false,
      });
      this.fg.get(key)?.get('to')?.setValidators(validators);
      this.fg.get(key)?.get('to')?.updateValueAndValidity({
        emitEvent: false,
      });
    });
  }

  private updateDayValidator(day: string) {
    const validators: ValidatorFn[] = [];

    if (this.eachDayEnabled && this.active && this.fg.get('active')?.value && this.fg.get(day)?.get('isOpen')?.value) {
      validators.push(Validators.required);
    }

    this.fg.get(day)?.get('from')?.setValidators(validators);
    this.fg.get(day)?.get('from')?.updateValueAndValidity({
      emitEvent: false,
    });
    this.fg.get(day)?.get('to')?.setValidators(validators);
    this.fg.get(day)?.get('to')?.updateValueAndValidity({
      emitEvent: false,
    });
  }
}
