import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { StorageService } from '../../services/storage.service';

@Injectable()
export class jwtOptionsFactory {
  public skipWhenExpired = false;
  public throwNoTokenError = false;
  public allowedDomains: RegExp[] | string[] = environment?.jwt?.allowedDomains ?? [];
  public disallowedRoutes: RegExp[] | string[] = environment?.jwt?.disallowedRoutes ?? [];

  public tokenGetter = () => {
    return StorageService.getItem('sh-t-p');
  };
}
