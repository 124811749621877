import { Component, forwardRef, HostBinding, Input } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { RadioComponent } from './radio/radio.component';

export interface RadioButtonValue {
  value: any;
  label: string;
  disabled?: boolean;
  isLocked?: any;
}

@Component({
  selector: 'ui-radio-buttons',
  templateUrl: './radio-buttons.component.html',
  styleUrls: ['./radio-buttons.component.scss'],
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioButtonsComponent),
      multi: true,
    },
  ],
  imports: [CommonModule, ReactiveFormsModule, TranslateModule, FormsModule, RadioComponent],
})
export class RadioButtonsComponent implements ControlValueAccessor {
  @Input() public values: RadioButtonValue[] = [];
  @Input() public name = `${Math.floor(
    new Date().getTime() * Math.random() * Math.random() * 100,
  )}`;
  @HostBinding('class.inline') @Input() public inline = true;
  public value = null;
  public disabled = false;
  private onTouch!: () => void;
  private onModelChange!: (value: any) => void;

  public registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public writeValue(value: any): void {
    this.value = value;
  }

  public valueChange($event: any) {
    this.value = $event;
    this.onModelChange(this.value);
    this.onTouch();
  }
}
