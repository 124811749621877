<ng-container *ngIf="loaded">
  <quill-editor
    [placeholder]="placeholder"
    [formControl]="control"
    [modules]="editorConfig"
    [formats]="allowedFormats"
    [class.readonly]="readonly"
    (drop)="false"
    (onEditorCreated)="editorInit($event)"
  ></quill-editor>
</ng-container>
