import { Directive, ElementRef, HostBinding, HostListener, Input, Optional, Renderer2 } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[formInput]',
  standalone: true,
})
export class FormControlDirective {
  public focused = false;
  @HostBinding('class.form-input') formInputClass = true;
  private isType = 'text';
  private isDisabled = false;
  private isRequired = false;
  private neverEmptyArray = ['date', 'datetime', 'datetime-local', 'month', 'time', 'week'];

  constructor(private elementRef: ElementRef, private renderer: Renderer2, @Optional() public ngControl: NgControl) {}

  private _placeholder = '';

  @HostBinding()
  @Input()
  get placeholder() {
    return this._placeholder;
  }

  set placeholder(value: string) {
    this._placeholder = value;
  }

  private _id!: string;

  @HostBinding()
  @Input()
  get id() {
    return this._id || this._name;
  }

  set id(value: string) {
    this._id = value;
  }

  private _name!: string;

  @HostBinding()
  @Input()
  get name() {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  @HostBinding()
  @Input()
  get disabled() {
    return this.ngControl ? this.ngControl.disabled : this.isDisabled;
  }

  set disabled(value: any) {
    this.isDisabled = !!value;
  }

  @Input()
  get type() {
    return this.isType;
  }

  set type(value: string) {
    this.isType = value || 'text';
    if (this.elementRef.nativeElement && this.elementRef.nativeElement.nodeName.toLowerCase() === 'input') {
      this.renderer.setAttribute(this.elementRef.nativeElement, 'type', this.isType);
    }
  }

  @HostBinding()
  @Input()
  get required() {
    return (this.isRequired =
      (this.ngControl && this.ngControl.errors && this.ngControl?.errors?.['required']) || false);
  }

  set required(value: any) {
    this.isRequired = !!value;
  }

  get value() {
    return this.elementRef.nativeElement.value;
  }

  set value(value: string) {
    this.elementRef.nativeElement.value = value;
  }

  get empty() {
    return (
      !(this.neverEmptyArray.indexOf(this.isType) !== -1) &&
      (this.value == null || this.value === '') &&
      !(this.elementRef.nativeElement as HTMLInputElement).validity.badInput
    );
  }

  get wrapperElement() {
    return this.elementRef.nativeElement.parentNode;
  }

  get labelElement() {
    return this.wrapperElement.querySelector('label');
  }

  get inputPhoneElement() {
    return this.wrapperElement.querySelector('.form-input-number');
  }

  focus() {
    if (!this.inputPhoneElement) {
      this.elementRef.nativeElement.focus();
    }
  }

  blur() {
    if (!this.inputPhoneElement) {
      this.elementRef.nativeElement.blur();
    }
  }

  @HostListener('focus')
  onFocus() {
    this.focused = true;
    if (this.labelElement) {
      this.labelElement.classList.add('active');
    }
  }

  @HostListener('blur')
  onBlur() {
    this.focused = false;
    if (this.labelElement) {
      this.labelElement.classList.remove('active');
    }
  }
}
