import { Component, HostBinding, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { LoaderComponent } from '@ui/common';

@Component({
  selector: 'ui-button-pill',
  templateUrl: './button-pill.component.html',
  standalone: true,
  imports: [CommonModule, AngularSvgIconModule, LoaderComponent],
  styleUrls: ['./button-pill.component.scss'],
})
export class ButtonPillComponent {
  @Input() text!: string;
  @Input() icon!: string;
  @Input() iconRight?: string;
  @Input() disabled = false;
  @Input() arrow = false;
  @Input() arrowRight = false;
  @Input() iconSmall = false;
  @Input() type: 'quaternary' | 'secondary' | 'primary' | 'black' | 'white' | 'tertiary' | 'dark' | 'yellow' =
    'quaternary';
  @Input() size: 's' | 'm' = 's';
  @HostBinding('class.active') @Input() active = false;
  @HostBinding('class.hover') @Input() hover = true;

  get iconPath(): string {
    if (this.icon) {
      return `/assets/icons/${this.icon}.svg`;
    }
    return '';
  }

  get iconRightPath(): string {
    if (this.iconRight) {
      return `/assets/icons/${this.iconRight}.svg`;
    }
    return '';
  }

  get iconSize(): string {
    return this.iconSmall ? 'button-icon-small' : '';
  }

  get typeClass(): string {
    return `button-type-${this.type}`;
  }

  get sizeClass(): string {
    return `button-size-${this.size}`;
  }
}
