import { Component, Input, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'ui-flag-icon',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './flag-icon.component.html',
  styleUrls: ['./flag-icon.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class FlagIconComponent {
  @Input() country?: string;

  get countryCode(): string {
    switch (this.country?.toLowerCase()) {
      case 'cs':
        return 'cz';
      case 'en':
        return 'gb';
      case 'uk':
        return 'ua';
      default:
        return this.country?.toLowerCase() ?? '';
    }
  }
}
