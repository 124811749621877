import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { IMediaFile } from '@smarttypes/core';

@Component({
  selector: 'sh-form-avatar',
  templateUrl: './form-avatar.component.html',
  styleUrls: ['./form-avatar.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class FormAvatarComponent {
  @Input() public avatar!: string;
  @Input() public avatarRounded = false;
  @Input() public label: string | null = 'Your picture';
  @Input() public type: 'user' | 'property' = 'user';
  @Input() public library = true;
  @Output() public removeAvatar = new EventEmitter<string>();
  @Output() public changeAvatar = new EventEmitter<string>();

  get isProperty(): boolean {
    return this.type === 'property';
  }

  public onChange(file: IMediaFile) {
    this.avatar = `https://${file?.url}` || '';
    this.changeAvatar.emit(this.avatar);
  }

  public onRemove(): void {
    this.avatar = '';
    this.removeAvatar.emit(this.avatar);
  }
}
