import { CommunicationChannelEnum, IBaseEntity } from '../common';
import { IVaWidgetCategory } from './va-widget-category.interface';
import { CompanyStatusEnum, CompanyTypeEnum } from '../company';
import { IWorkingHours } from './working-hours.interface';
import { IApartmentWifiFromConfig } from './apartment-wifi-from-config.interface';

export interface IVisitorsArea extends IBaseEntity {
  name: string;
  companyId: string;
  mainComponent: CompanyTypeEnum;
  readonly domain?: string;
  subdomain?: string;
  accentColor?: string;
  readonly url?: string;
  readonly subdomainLocked?: boolean;
  topMessage?: Record<string, string>;
  topMessage2?: Record<string, string>;
  languages?: string[];
  logotype?: string;
  // @deprecated
  communication?: { [key in CommunicationChannelEnum]?: string };
  widgets: IVaWidgetCategory[];
  companyStatus?: CompanyStatusEnum;
  companyPlan?: string;
  companyModules?: string[];
  companyTimezone?: string;
  lastPublishedAt?: Date;
  lastDraftSavedAt?: Date;
  lastPublishBy?: IVisitorsAreaPublishBy;
  coverType?: CoverTypeEnum;
  visualCover?: IVisualCover[];
  brightLogo?: string;
  brightLogoOriginal?: string;
  logotypeOriginal?: string;
  readonly secured?: boolean;
  isRegistrationEnabled?: boolean;
  companyRooms?: { roomNo: number }[];
  workingHours: IWorkingHours;
  wifi?: IApartmentWifiFromConfig[];
}

export interface IVisitorsAreaPublishBy {
  id: string;
  displayName?: string;
  email?: string;
  phone?: string;
}

export interface IVisualCover {
  font: CoverFontEnum;
  video?: string;
  background: string;
  backgroundOriginal: string;
  subtitle: Record<string, string>;
  title: Record<string, string>;
}

export enum CoverTypeEnum {
  Simple = 'Simple',
  Visual = 'Visual',
}

export enum CoverFontEnum {
  Default = 'Default',
  Calligraphic = 'Calligraphic',
  Strong = 'Strong',
}
