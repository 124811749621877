<ng-container *ngIf="isMobile; else desktop">
  <iframe *ngIf="showMobileApp; else placeholder" [src]="mobileAppUrl"></iframe>
  <ng-template #placeholder>
    <ui-mobile-device-message></ui-mobile-device-message>
  </ng-template>
</ng-container>
<ng-template #desktop>
  <main [@fadeAnimation]="getRouterOutletState(o)">
    <router-outlet #o="outlet"></router-outlet>
  </main>
</ng-template>
