import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CommonModule } from '@angular/common';
import { ButtonCircleComponent } from '@ui/common/buttons';
import { TranslateModule } from '@ngx-translate/core';
import { NlToBrPipe } from 'angular-v2-pipes';
import { AngularSvgIconModule } from 'angular-svg-icon';

@Component({
  selector: 'ui-modal-base',
  templateUrl: './base-modal.component.html',
  styleUrls: ['./base-modal.component.scss'],
  standalone: true,
  imports: [CommonModule, ButtonCircleComponent, TranslateModule, NlToBrPipe, AngularSvgIconModule],
})
export class BaseModalComponent {
  @Input() canClose = true;
  @Input() header?: string;
  @Input() subtitle?: string;
  @Input() coverImage?: string;
  @Input() icon?: string;
  @Input() subtitleHtml = false;
  @Output() dismiss = new EventEmitter();
  @Input() @HostBinding('class.padding') public padding = true;
  @Input() @HostBinding('class.large') public large = false;

  constructor(public modal: BsModalRef) {}

  onClose() {
    this.modal.hide();
    this.dismiss.emit(true);
  }
}
