import { Component } from '@angular/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'ui-grabber',
  standalone: true,
  imports: [CommonModule, AngularSvgIconModule],
  templateUrl: './grabber.component.html',
  styleUrls: ['./grabber.component.scss'],
})
export class GrabberComponent {}
