import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { FileLibraryComponent } from './file-library.component';

export enum FileTagEnum {
  main = 'main',
  cover = 'cover',
  logo = 'logo',
  avatar = 'avatar',
  attachment = 'attachment',
  email = 'email',
}

export enum FileTypeEnum {
  image = 'image',
  pdf = 'pdf',
  video = 'video',
  audio = 'audio',
  any = 'any',
}

@Directive({
  selector: '[uiFileLibrary]',
  standalone: true,
})
export class FileLibraryDirective {
  @Output() fileChange = new EventEmitter();
  @Input() tag!: FileTagEnum;
  @Input() type: FileTypeEnum = FileTypeEnum.any;
  @Input() disabled = false;
  @Input() maxFileSize = 5242880;
  @Input() acceptFiles: string[] = ['image/jpg', 'image/jpeg', 'image/png', 'image/svg+xml'];
  @Input() library = true;
  @Input() folder?: string;

  constructor(private modalService: BsModalService) {}

  @HostListener('click')
  onClick() {
    if (!this.disabled) {
      this.openModal();
    }
  }

  openModal() {
    const modal = this.modalService.show(FileLibraryComponent, {
      class: 'modal-file-library',
      initialState: {
        tag: this.tag,
        type: this.type,
        library: this.library,
        folder: this.folder,
        maxFileSize: this.maxFileSize,
        acceptFiles: this.acceptFiles,
      },
    });
    modal?.content?.action?.subscribe((res: any) => {
      this.fileChange.emit(res);
    });
  }
}
