import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ErrorMessageComponent } from '@ui/common/forms';
import { ButtonRectangleComponent } from '@ui/common/buttons';

export interface DeleteModelText {
  header?: string;
  body?: string;
  submit?: string;
}

@Component({
  selector: 'ui-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.scss'],
  imports: [ErrorMessageComponent, ButtonRectangleComponent, TranslateModule],
  standalone: true,
})
export class DeleteModalComponent implements OnInit {
  @HostBinding('class.modal-info') modalInfo = true;
  public pending = false;
  public hasWarning = false;
  public formErrors: any;
  @Input() text!: DeleteModelText;
  @Input() deleteFunc!: (force?: boolean) => Observable<any>;
  @Input() callback!: () => void;
  public txt!: DeleteModelText;
  private txtDefault: DeleteModelText = {
    header: 'SH.DELETE',
    body: 'SH.DELETE',
    submit: 'SH.DELETE',
  };

  constructor(
    public bsModalRef: BsModalRef,
    private toastr: ToastrService,
    private translateService: TranslateService,
  ) {}

  ngOnInit() {
    this.txt = Object.assign({}, this.txtDefault, this.text);
  }

  onDeleteClick() {
    this.formErrors = null;
    if (this.deleteFunc) {
      this.pending = true;
      this.deleteFunc(this.hasWarning).subscribe(
        res => {
          if (Array.isArray(res?.warnings) && res?.warnings.length > 0) {
            this.pending = false;
            this.hasWarning = true;
            this.formErrors = res.warnings;
          } else {
            this.bsModalRef.hide();
            this.toastr.info(this.translateService.instant('SH.REMOVED'));
          }
          this.callback();
        },
        err => {
          this.pending = false;
          if (Array.isArray(err?.warnings) && err?.warnings.length > 0) {
            this.hasWarning = true;
            this.formErrors = err.warnings;
          } else {
            this.formErrors = err;
          }
        },
      );
    }
  }
}
