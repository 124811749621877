import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonRectangleComponent } from '@ui/common/buttons';
import { distinctUntilChanged, fromEvent, map, Subscription, tap } from 'rxjs';

@Component({
  selector: 'wg-form-save-control',
  standalone: true,
  imports: [CommonModule, TranslateModule, ButtonRectangleComponent],
  templateUrl: './form-save-control.component.html',
  styleUrls: ['./form-save-control.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormSaveControlComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public date: Date | undefined;
  @Input() public author: string | undefined;
  @Input() public pending = false;
  @Input() public disabled = false;
  @Output() public publish = new EventEmitter<void>();
  @HostBinding('class.bottom') public isBottom = true;
  private subscription: Subscription = new Subscription();

  constructor(private cdr: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.subscription.add(
      fromEvent(window, 'scroll')
        .pipe(
          map((e: Event) => {
            const scrollEl = (e.target as Document)?.scrollingElement;
            return (scrollEl?.clientHeight ?? 0) + (scrollEl?.scrollTop ?? 0) >= (scrollEl?.scrollHeight ?? 0) - 35;
          }),
          distinctUntilChanged(),
          tap(state => {
            this.isBottom = state;
            this.cdr.detectChanges();
          }),
        )
        .subscribe(),
    );
  }

  public onPublish() {
    if (!this.disabled || !this.pending) {
      this.publish.emit();
    }
  }

  public ngOnChanges(changes: SimpleChanges) {
    this.pending = changes?.['pending']?.currentValue ?? false;
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
