import { Component, Input } from '@angular/core';
import { IMenuItem } from '../sidebar/menu-group/menu-group.component';
import { CommonModule } from '@angular/common';
import { RouterLinkActive, RouterLinkWithHref } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'sh-top-navigation',
  templateUrl: './top-navigation.component.html',
  styleUrls: ['./top-navigation.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterLinkWithHref, TranslateModule, RouterLinkActive],
})
export class TopNavigationComponent {
  @Input() items: IMenuItem[] = [];
}
