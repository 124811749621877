import { IGeneratePropertyName, IRoom } from '@smarttypes/hotel';
import { isEmpty, isNil, get } from 'lodash';

export function generatePropertyNameFromRoom(apartment: IRoom): string {
  return generatePropertyName({
    propertyName: get(apartment, 'apartmentInfo.additionalInfo.propertyName') as string | undefined,
    address: apartment.apartmentInfo?.address,
    roomNo: apartment.roomNo,
    roomType: apartment.roomType,
    apartmentId: `${apartment._id}`,
  });
}

export function generatePropertyName(nameParts: IGeneratePropertyName): string {
  if (nameParts.propertyName) {
    return nameParts.propertyName;
  }

  const street = nameParts.address?.street;
  const city = nameParts.address?.city;
  const apartmentNo = nameParts.address?.apartmentNo;

  const parts = [city, street].filter(s => !isNil(s) && !isEmpty(s)).map(e => e?.trim());

  if (apartmentNo) {
    parts.push(`Apart. ${apartmentNo}`);
  }

  if (parts.length > 0) {
    return parts.join(' · ');
  }

  [nameParts.roomNo, nameParts.roomType].filter(s => !isNil(s) && !isEmpty(s)).forEach(e => parts.push(e?.trim()));

  return parts.length > 0 ? parts.join(' ') : `${nameParts.apartmentId}`;
}
