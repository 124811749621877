import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';

@Component({
  selector: 'wg-form-add-button',
  standalone: true,
  imports: [CommonModule, TranslateModule, AngularSvgIconModule],
  templateUrl: './form-add-button.component.html',
  styleUrls: ['./form-add-button.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class FormAddButtonComponent {
  @Input() public disabled = false;
  @Output() public add = new EventEmitter<void>();

  constructor(private readonly _cd: ChangeDetectorRef) {}

  public onButtonClick(): void {
    if (this.disabled) {
      return;
    }
    this.add.emit();
  }
}
