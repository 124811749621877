import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PanelInputComponent } from './panel-input/panel-input.component';
import { PanelBoxComponent } from './panel-box/panel-box.component';
import { PanelCompaniesBarComponent } from './panel-companies-bar/companies-bar.component';
import { NgxTypedJsModule } from 'ngx-typed-js';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [PanelBoxComponent, PanelCompaniesBarComponent, PanelInputComponent],
  imports: [CommonModule, NgxTypedJsModule, TranslateModule, AngularSvgIconModule, FormsModule, ReactiveFormsModule],
  exports: [PanelBoxComponent, PanelCompaniesBarComponent, PanelInputComponent],
})
export class PanelModule {}
