import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { IMessageStats, MessageCountTabEnum } from '@smarttypes/metrics';
import { MessagesSentByChannelExpensesItemComponent } from '../messages-sent-by-channel-expenses-item';
import { TipComponent } from '@ui/common';
import { ButtonCircleComponent } from '@ui/common/buttons';
import { NgxTippyModule } from 'ngx-tippy-wrapper';

export interface IMessageExpansesStatsGroup {
  stats: IMessageStats[];
  channel: MessageCountTabEnum;
}

@Component({
  selector: 'ui-messages-sent-by-channel-expenses',
  templateUrl: './messages-sent-by-channel-expenses.component.html',
  styleUrls: ['./messages-sent-by-channel-expenses.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    AngularSvgIconModule,
    MessagesSentByChannelExpensesItemComponent,
    TipComponent,
    ButtonCircleComponent,
    NgxTippyModule,
  ],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessagesSentByChannelExpensesComponent {
  @Input() downloading = false;
  @Output() export = new EventEmitter<void>();
  currency = '';
  allExpenses = '0';
  isLoading = true;
  private _stats: IMessageExpansesStatsGroup[] | undefined = [];

  @Input() set stats(value: IMessageExpansesStatsGroup[] | undefined) {
    this._stats = value;
    this.setData();
    this.isLoading = false;
    this.cdr.detectChanges();
  }

  get stats(): IMessageExpansesStatsGroup[] {
    return this._stats ?? [];
  }

  constructor(private cdr: ChangeDetectorRef) {}

  getTooltipForItem(item: IMessageExpansesStatsGroup): string {
    if (item.channel === MessageCountTabEnum.other) {
      return 'SH.ANALYTICS_VIEW.MESSAGES_SENT_BY_CHANNEL.OTHER.TIP';
    }
    return '';
  }

  private setData(): void {
    this.allExpenses = (
      this.stats
        ?.flatMap(group => group.stats)
        .reduce((sum, stat) => {
          this.currency = stat?.price?.includes('$') ? stat?.price?.split(' ')[0] : stat?.price?.split(' ')[1] || '';
          return sum + (stat.price ? parseFloat(stat?.price?.split(' ')[0]) : 0);
        }, 0) || 0
    ).toFixed(1);
  }
}
