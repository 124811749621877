import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { downloadFile } from 'angular-v2-utils';

export interface IPdfRq {
  url: string;
  pageFormat?: string;
  width?: string;
  height?: string;
  templateParams: object;
  outputPath: string;
  language?: string;
}

export interface IPfdBulkRq {
  pdfItems: IPdfRq[];
  zipFileName: string;
}

export interface IPdfBulkRes {
  signed: string;
  url: string;
}

@Injectable({
  providedIn: 'root',
})
export class PdfService {
  constructor(private readonly httpClient: HttpClient) {}

  public generatePdf(rq: IPdfRq): Observable<ArrayBufferLike> {
    return this.httpClient.post<ArrayBufferLike>(`/pdf`, rq, {
      responseType: 'arraybuffer' as 'json',
    });
  }

  public generatePdfPackage(rq: IPfdBulkRq): Observable<IPdfBulkRes> {
    return this.httpClient.post<IPdfBulkRes>(`/pdf/bulk`, rq);
  }

  public downloadQrPdf(link: string, appUrl: string, fileName?: string) {
    return this.generatePdf({
      url: `${appUrl}/qr-material/qrcode/qrcode`,
      pageFormat: 'a4',
      templateParams: {
        url: link,
        campaign: '',
      },
      language: 'en',
      outputPath: `widget/qrcode`,
    }).pipe(tap(file => downloadFile(file, `${fileName ?? 'qr'}.pdf`, 'application/pdf')));
  }
}
