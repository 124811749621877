import { Component, HostBinding, Injector, OnDestroy, OnInit, Optional } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { catchError, delay, filter, finalize, switchMap } from 'rxjs/operators';
import { forkJoin, map, Subscription, tap, throwError } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { AccountService } from './core/services/account.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { environment } from '../environments/environment';
import { untilDestroyed } from 'angular-v2-utils';
import { fadeAnimation } from 'angular-v2-animations';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { MobileWebAppService } from './core/services/mobile-webapp.service';
import { PushNotificationsService } from './core/services/push-notifications.service';
import { AuthService } from './core/services/auth.service';
import { CompanyService } from './core/services/company.service';
import * as Sentry from '@sentry/angular-ivy';
import { getDisplayNameSignature } from '@smarthotel/utils';
import { GlobalHostListenerService } from 'angular-v2-services';

@Component({
  selector: 'sh-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [fadeAnimation],
})
export class AppComponent implements OnInit, OnDestroy {
  public mobileAppUrl: SafeUrl | null = null;
  private gtmService?: GoogleTagManagerService;
  private subscriptions: Subscription = new Subscription();

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly translateService: TranslateService,
    private readonly toast: ToastrService,
    private readonly accountService: AccountService,
    private readonly authService: AuthService,
    private readonly companyService: CompanyService,
    private readonly injector: Injector,
    private readonly deviceService: DeviceDetectorService,
    private readonly sanitizer: DomSanitizer,
    private readonly mobileWebAppService: MobileWebAppService,
    @Optional() private readonly push: PushNotificationsService,
    private readonly globalHostListenerService: GlobalHostListenerService,
  ) {}

  @HostBinding('class')
  public get releaseName(): string {
    return environment.releaseName;
  }

  isMobile = false;

  public get showMobileApp() {
    return this.mobileWebAppService.isMobileAppEnable;
  }

  public getRouterOutletState(outlet: RouterOutlet) {
    return outlet.isActivated ? outlet.activatedRoute : '';
  }

  public ngOnInit(): void {
    this.isMobile = this.deviceService.isMobile();

    this.mobileAppUrl = this.mobileWebAppService.mobileAppUrl;
    if (this.push) {
      this.push.init();
    }

    if (environment.sentryDns) {
      this.authService.$loggedIn
        .pipe(
          filter(e => e),
          switchMap(() => forkJoin([this.accountService.$currentUser(), this.companyService.$currentUser()])),
        )
        .subscribe(([user, company]) => {
          Sentry.setUser({
            id: user._id as string,
            email: user.email,
            username: getDisplayNameSignature(user),
          });
          Sentry.setContext('company', {
            id: company._id as string,
            name: company.name,
          });
        });
    }

    this.subscriptions.add(
      this.router.events
        .pipe(
          filter(event => event instanceof NavigationEnd),
          map(() => this.activatedRoute.snapshot.queryParamMap.get('token') as string),
          filter(t => !!t),
          switchMap(token => {
            return this.accountService.verifyTokenEmail(token).pipe(
              finalize(() => {
                sessionStorage.removeItem('token-verify');
                this.toast.success(`${this.translateService.instant('SH.ACCOUNT.ACCOUNT_ACTIVATED')}`);
              }),
              delay(1000),
              tap(() => {
                window.location.assign('/');
              }),
              catchError(err => {
                this.toast.error(`${this.translateService.instant('SH.ACCOUNT.INVALID_VERIFICATION_CODE')}`);
                return throwError(() => err);
              }),
            );
          }),
          untilDestroyed(this),
        )
        .subscribe(),
    );

    if (environment?.gtmId) {
      this.loadGtmService();
      this.gtmService?.addGtmToDom().catch(() => {});
    }
  }

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  private loadGtmService() {
    this.gtmService = this.injector.get(GoogleTagManagerService);
  }
}
