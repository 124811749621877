import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { map, take } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class LoggedInGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot) {
    return this.auth.$loggedIn.pipe(
      take(1),
      map(e => {
        if (window.location.href.includes('auth/external')) {
          return true;
        }
        const token = route.queryParamMap.get('refreshToken');
        if (e && !token) {
          this.router.navigate(['/']);
          return false;
        } else {
          return true;
        }
      }),
    );
  }
}
