import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from '../../services/storage.service';

const FALLBACK_LANGUAGE = 'en';

@Injectable({
  providedIn: 'root',
})
export class ApplicationLanguageService {
  private availableLanguages = ['en', 'pl', 'es'];

  constructor(private translateService: TranslateService) {}

  setLanguage(language: string | undefined, callback?: () => void) {
    if (!language) {
      return;
    }
    if (this.canSetLanguage(language)) {
      this.translateService.setDefaultLang(language);
      this.translateService.use(language);
      StorageService.setItem('sh-admin-lang', language);
      if (callback) {
        callback();
      }
    }
  }

  get getDefaultLanguage(): string {
    return FALLBACK_LANGUAGE;
  }

  getCurrentLanguage(): string {
    return StorageService.getItem('sh-admin-lang') ?? this.getDefaultLanguage;
  }

  private canSetLanguage(language: string): boolean {
    return this.availableLanguages.includes(language);
  }
}
