import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { RequestTypeEnum } from '@smarttypes/core';
import { RequestIconsComponent } from '../request-icons';

@Component({
  selector: 'ui-request-type',
  templateUrl: './request-type.component.html',
  styleUrls: ['./request-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RequestIconsComponent],
})
export class RequestTypeComponent {
  @HostBinding('class')
  @Input()
  type?: RequestTypeEnum;
}
