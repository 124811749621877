import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { extension, lookup } from 'mime-types';
import { CommonModule } from '@angular/common';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgxFilesizeModule } from 'ngx-filesize';

@Component({
  selector: 'ui-file-drop-down',
  standalone: true,
  imports: [CommonModule, AngularSvgIconModule, TranslateModule, NgxFilesizeModule],
  templateUrl: './file-drop-down.component.html',
  styleUrls: ['./file-drop-down.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileDropDownComponent {
  @Input() public maxFileSize = 5242880;
  @Input() public height = '660px';
  @Input() public acceptFiles: string[] = ['image/jpg', 'image/jpeg', 'image/png', 'image/svg+xml'];
  @Output() public fileChange = new EventEmitter<File>();
  public fileOver = false;

  constructor(
    private cd: ChangeDetectorRef,
    private toast: ToastrService,
    private translateService: TranslateService,
  ) {}

  public get allowedFiles(): string {
    return this.acceptFiles
      .map(af => {
        return extension(af) as string;
      })
      .filter(s => !!s)
      .join(', ');
  }

  public fileChangeEvent(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (!input.files?.length) {
      return;
    }
    const file = input.files[0];

    if (file.size > this.maxFileSize) {
      this.toast.error(this.translateService.instant('SH.FRONTEND_LIB.FILE_TOO_LARGE'));

      return;
    }

    if (file) {
      this.fileChange.emit(file);
    }
  }

  public dragLeaveOver(event: DragEvent, fileOver: boolean) {
    this.preventAndStop(event);
    this.fileOver = fileOver;
    this.cd.detectChanges();
  }

  public drop(event: DragEvent) {
    this.preventAndStop(event);
    const transfer = event.dataTransfer;
    if (!transfer) {
      return;
    }
    const mimeType: string = lookup(transfer.files[0].name) as string;
    if (mimeType && this.acceptFiles.includes(mimeType)) {
      this.fileChange.emit(transfer.files[0]);
    } else {
      this.toast.error(
        `${this.translateService.instant('SH.FRONTEND_LIB.FILE_DROP_DOWN.INVALID_TYPE')} ${this.allowedFiles}`,
      );
    }
    this.fileOver = false;
    this.cd.detectChanges();
  }

  protected preventAndStop(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }
}
