import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class MobileWebAppService {
  private mobileAppEnabled = true;

  constructor(private readonly sanitizer: DomSanitizer) {}

  public get isMobileAppEnable(): boolean {
    return this.mobileAppEnabled;
  }

  public get mobileAppUrl(): SafeResourceUrl | null {
    if (!environment.appMobileUrl) {
      return null;
    }
    return this.sanitizer.bypassSecurityTrustResourceUrl(environment.appMobileUrl);
  }

  public setMobileAppVisibility(visible: boolean) {
    this.mobileAppEnabled = visible;
  }
}
