import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { ALL_FUNCTIONALITIES, ICompanyRole } from '@smarttypes/core';
import { Subscription } from 'rxjs';
import { CompanyService } from '../core/services/company.service';
import { hasAtLeastOneModule } from 'angular-v2-utils';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[smartCoreHasOneModule]',
})
export class HasOneModuleDirective implements OnInit, OnChanges, OnDestroy {
  private subscription: Subscription = new Subscription();
  private companyRole!: ICompanyRole;

  constructor(
    private element: ElementRef,
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef,
    protected readonly companyService: CompanyService,
  ) {}

  @Input() smartCoreHasOneModule!: ALL_FUNCTIONALITIES[];

  public ngOnInit(): void {
    this.subscription.add(
      this.companyService.$currentUser().subscribe(compamny => {
        this.companyRole = compamny;
        this.updateView();
      }),
    );
  }

  private updateView() {
    if (this.checkPermission()) {
      this.viewContainer.clear();
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  private checkPermission() {
    return hasAtLeastOneModule(this.companyRole?.modulesV2, this.smartCoreHasOneModule);
  }

  ngOnChanges(): void {
    this.updateView();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
