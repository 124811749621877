import {
  AfterContentInit,
  Component,
  ElementRef,
  forwardRef,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgSelectComponent, NgSelectModule } from '@ng-select/ng-select';
import { ICountry, Timezones } from '@smarttypes/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { FlagIconComponent } from '@ui/common';

@Component({
  selector: 'ui-select-country',
  templateUrl: './select-country.component.html',
  styleUrls: ['./select-country.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  standalone: true,
  imports: [CommonModule, AngularSvgIconModule, NgSelectModule, FormsModule, TranslateModule, FlagIconComponent],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectCountryComponent),
      multi: true,
    },
  ],
})
export class SelectCountryComponent implements ControlValueAccessor, OnChanges, AfterContentInit, OnDestroy {
  @Input() locked = false;
  @Input() items: any[] = [];
  @Input() loading = false;
  @Input() searchable = false;
  @Input() typeToSearchText!: string;
  @Input() readonly = false;
  @Input() icon: string | null = null;
  @Input() type = 'ng-select-pill';
  @Input() placeholder = '';
  @Input() showPrefix = true;
  @Input() setInitialCountry = true;
  @Input() arrow = true;
  @Input() bindValue!: string;
  @Input() virtualScroll = true;
  @Input() guestArea = false;
  @Input() disableLangSwitch = false;
  @Input() clearable = false;
  @Input() countryCodeForSelected = false;
  @ViewChild('select', { static: true }) select!: NgSelectComponent;
  @ViewChild('searchInput') searchInput!: ElementRef<HTMLInputElement>;
  @HostBinding('class.with-label') @Input() showLabel = false;
  public filteredItems: ICountry[] = [];
  public value: string | null = null;
  private onModelChange!: (value: any) => void;
  private onTouch!: () => void;
  private isRegister = false;

  constructor(private translateService: TranslateService) {}

  get iconPath(): string {
    if (this.icon) {
      return `/icons/${this.icon}.svg`;
    }
    return '';
  }

  public ngAfterContentInit(): void {
    if (this.isRegister) {
      this.setCountry();
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.isRegister && !this.disableLangSwitch) {
      this.setCountry();
    }
    if (changes['items'] && changes['items']?.currentValue?.length) {
      this.filteredItems = [...changes['items'].currentValue];
    }
  }

  public ngOnDestroy() {
    console.log();
  }

  public onFilterChange(event: Event): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const value = event?.target?.value?.toLowerCase();
    const countries: ICountry[] = this.items as ICountry[];
    this.filteredItems = countries.filter((item: ICountry) => {
      const prefix = `+${item?.callingCodes[0].toLowerCase()}`;
      return prefix.indexOf(value) > -1 || item?.name?.toLowerCase().indexOf(value) > -1;
    });

    return;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.locked = isDisabled;
  }

  public onClose(): void {
    setTimeout(() => {
      this.filteredItems = [...this.items];
    }, 400);
  }

  public registerOnChange(fn: () => void): void {
    this.isRegister = true;
    this.onModelChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  public writeValue(data: any): void {
    this.value = data;
  }

  public changeValue(val: any): void {
    this.value = val;
    if (this.isRegister) {
      this.onTouch();
      this.onModelChange(this.value);
    }
  }

  public hasPrefix(country: ICountry): boolean {
    return country?.callingCodes?.length > 0 && country?.callingCodes[0] !== '';
  }

  public onOpen() {
    if (this.searchable) {
      setTimeout(() => this.searchInput?.nativeElement?.focus(), 50);
    }
  }

  private getInitialCountry() {
    const localStorageLangKey = this.guestArea ? 'sh-language' : 'sh-admin-lang';
    const savedLanguage = this.findCountry(localStorage.getItem(localStorageLangKey) as string);
    const browserLanguage = this.findCountry(this.translateService.getBrowserLang());
    const defaultLanguage = this.findCountry('en');

    if ((savedLanguage?.length ?? 0) > 0) {
      return savedLanguage;
    } else if ((browserLanguage?.length ?? 0) > 0) {
      return browserLanguage;
    } else {
      return defaultLanguage;
    }
  }

  private findCountry(code: string | undefined) {
    const formatIntl = new Intl.DateTimeFormat();
    const usedOptions = formatIntl.resolvedOptions();
    const timezone = usedOptions.timeZone;
    let countryCode = code === 'en' ? 'us' : code;

    if (timezone === Timezones.America_Santo_Domingo) {
      countryCode = 'do';
    }

    return this.items.filter(item => item?.alpha2Code?.toLocaleLowerCase() === countryCode);
  }

  private setCountry() {
    if (this.setInitialCountry) {
      const country = this.getInitialCountry();
      this.writeValue(country[0]?.iso639_1 || country[0]);
      this.onModelChange(country[0]?.iso639_1 || country[0]);
    }
  }
}
