import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VisitorsApartmentComponent } from './visitors-apartment.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { CardModule, FrontendUiModule } from '@smarthotel/angular-ui';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { QuillModule } from 'ngx-quill';
import { ImageCropperModule } from 'ngx-image-cropper';
import { DirectivesModule } from '../../../directives/directives.module';
import { LocalizedDatePipe } from 'angular-v2-pipes';
import { DragNDropDirective, FormControlDirective } from 'angular-v2-directives';
import { PropertySwitcherComponent } from './property-switcher/property-switcher.component';
import { FormPropertyBaseComponent } from './form-property-base/form-property-base.component';
import { FormPropertyInstructionsComponent } from './form-property-instructions/form-property-instructions.component';
import { FormPropertyStayComponent } from './form-property-stay/form-property-stay.component';
import { ComponentsModule } from '../../../components/components.module';
import {
  AvatarComponent,
  CardComponent,
  DividerComponent,
  LinkedObjectsButtonComponent,
  LoaderComponent,
} from '@ui/common';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import {
  CheckboxComponent,
  ErrorMessageComponent,
  FormComponent,
  MasterButtonsComponent,
  PhoneNumberComponent,
  SelectComponent,
  SelectCountryComponent,
} from '@ui/common/forms';
import { PropertyPreviewNoteComponent } from './property-preview-note/property-preview-note.component';
import {
  ButtonCircleComponent,
  ButtonPillComponent,
  ButtonRectangleComponent,
  ButtonSquareComponent,
} from '@ui/common/buttons';
import { BannerComponent, EmptyComponent, SegmentsComponent, WysiwygEditorComponent } from '@ui/features';
import { FormPropertySkeletonComponent } from './form-property-skeleton/form-property-skeleton.component';
import { FormPropertyStaySectionComponent } from './form-property-stay/form-property-stay-section/form-property-stay-section.component';
import { PropertyActionsComponent } from './property-actions/property-actions.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { FormHoursComponent, FormIconComponent } from '@widgets/front-common/form';
import { PropertyPickerComponent } from './property-picker/property-picker.component';
import { BaseModalComponent, LinkedObjectsModalComponent } from '@ui/common/modals';
import { TourNgxBootstrapModule } from 'ngx-ui-tour-ngx-bootstrap';
import { SmartUiControlModule } from '@ui/packages/smart-ui-control';
import { environment } from '../../../../environments/environment';
import { SyncDataButtonComponent } from './form-property-base/sync-data-button/sync-data-button.component';
import { FormWidgetsModule } from '../visitors-form/form-widgets/form-widgets.module';
import { FormPropertyStayCustomComponent } from './form-property-stay/form-property-stay-custom/form-property-stay-custom.component';

@NgModule({
  declarations: [
    VisitorsApartmentComponent,
    PropertySwitcherComponent,
    FormPropertyBaseComponent,
    FormPropertyInstructionsComponent,
    FormPropertyStayComponent,
    PropertyPreviewNoteComponent,
    FormPropertySkeletonComponent,
    FormPropertyStaySectionComponent,
    PropertyActionsComponent,
    PropertyPickerComponent,
    SyncDataButtonComponent,
    FormPropertyStayCustomComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    ReactiveFormsModule,
    CardModule,
    AngularSvgIconModule,
    FrontendUiModule,
    QuillModule,
    ImageCropperModule,
    DirectivesModule,
    LocalizedDatePipe,
    DragNDropDirective,
    FormControlDirective,
    ComponentsModule,
    AvatarComponent,
    BsDropdownModule,
    FormComponent,
    CardComponent,
    ErrorMessageComponent,
    DividerComponent,
    ButtonRectangleComponent,
    SelectComponent,
    ButtonPillComponent,
    ButtonSquareComponent,
    SelectCountryComponent,
    EmptyComponent,
    BannerComponent,
    NgxSkeletonLoaderModule,
    MasterButtonsComponent,
    FormHoursComponent,
    PhoneNumberComponent,
    LinkedObjectsButtonComponent,
    LinkedObjectsModalComponent,
    BaseModalComponent,
    CheckboxComponent,
    LoaderComponent,
    SegmentsComponent,
    TourNgxBootstrapModule,
    WysiwygEditorComponent,
    SmartUiControlModule.forRoot(environment.ui),
    FormWidgetsModule,
    FormIconComponent,
    ButtonCircleComponent,
  ],
  exports: [VisitorsApartmentComponent],
})
export class VisitorsApartmentModule {}
