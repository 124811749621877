import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IGeo } from '@smarttypes/core';
import { IAttachmentType, IMessageItemAttachment } from '@smarttypes/messages';
import { CommonModule } from '@angular/common';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { TranslateModule } from '@ngx-translate/core';
import { LinkProtocolPipe } from 'angular-v2-pipes';

@Component({
  selector: 'ui-attachment',
  templateUrl: './attachment.component.html',
  styleUrls: ['./attachment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, AngularSvgIconModule, TranslateModule, LinkProtocolPipe],
})
export class AttachmentComponent {
  @Input() attachment?: IMessageItemAttachment;
  attachmentsType = IAttachmentType;
  private googleMapsStatic = 'https://maps.googleapis.com/maps/api/staticmap';
  private googleKey = ''; // TODO: Google API Key

  public get isPdf(): boolean {
    return !!this.attachment?.title?.endsWith('.pdf');
  }

  getGoogleMapsStaticUrl(coordinates?: IGeo): string {
    return `${this.googleMapsStatic}?center=${coordinates?.lat},${coordinates?.lng}&zoom=15&scale=2&size=200x125&maptype=roadmap&key=${this.googleKey}&format=jpg&visual_refresh=true&markers=size:tiny%7Ccolor:0xff0000%7Clabel:0%7C${coordinates?.lat},${coordinates?.lng}`;
  }

  getGoogleMapsLinkUrl(coordinates?: IGeo): string {
    return `https://www.google.pl/maps/place/${coordinates?.lat},${coordinates?.lng}/@${coordinates?.lat},${coordinates?.lng},15z`;
  }
}
