import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { IMessageItem } from '@smarttypes/messages';
import { ReplyToReviewComponent } from '../reply-to-review/reply-to-review.component';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'ui-review-bcom',
  standalone: true,
  templateUrl: './review-bcom.component.html',
  styleUrls: ['./review-bcom.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ReviewBcomComponent),
      multi: true,
    },
  ],
  imports: [CommonModule, ReplyToReviewComponent, TranslateModule],
})
export class ReviewBcomComponent {
  @Input() message?: IMessageItem;
  @Output() public review: EventEmitter<string> = new EventEmitter<string>();
}
