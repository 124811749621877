import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { IVaWidget } from '@smarttypes/core';
import { ButtonRectangleComponent } from '@ui/common/buttons';

interface PeriodParsed {
  name?: string;
  displayName?: string;
  sum: number;
  change?: number;
  isPeriod?: boolean;
}

@Component({
  selector: 'ui-widgets-interaction-item',
  templateUrl: './widgets-interaction-item.component.html',
  styleUrls: ['./widgets-interaction-item.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, AngularSvgIconModule, ButtonRectangleComponent],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetsInteractionItemComponent {
  @Input() public vaWidget?: IVaWidget | any;
  @Input() public widget?: PeriodParsed;
  @Input() public isApartment = false;

  public get widgetIcon(): string {
    const category = this.normalizeName(this.widget?.name).split('-')[0];
    const icon = this.vaWidget?.icon;

    if (this.isEmoji) {
      return this.getEmojiIcon(icon);
    }

    if (!icon && this.vaWidget?.image?.length) {
      return this.getImageIcon(this.vaWidget);
    }

    const staticIcon = this.getStaticIcon(this.widget?.name);
    if (staticIcon) {
      return `/assets/icons/${staticIcon}.svg`;
    }

    const mealIcon = this.getMealIcon(this.widget?.name);
    if (mealIcon) {
      return mealIcon;
    }

    return this.getCategoryIcon(category, icon);
  }

  public get changeIsPositive(): boolean {
    return (this.widget?.change || 0) > 0;
  }

  public get changeIsNegative(): boolean {
    return (this.widget?.change || 0) < 0;
  }

  public get isEmoji(): boolean {
    const ranges = [
      '(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])', // U+1F680 to U+1F6FF
    ];
    return !!this.vaWidget?.icon?.match(ranges?.join('|'));
  }

  public get isSvg(): boolean {
    return this.widgetIcon.endsWith('.svg');
  }

  private normalizeName(name: string | undefined): string {
    return name?.toLowerCase().replace('_', '-') || '';
  }

  private getEmojiIcon(icon: string | undefined): string {
    return icon || '';
  }

  private getImageIcon(item: any): string {
    return `https://${item?.image[0]?.url}`;
  }

  private getMealIcon(widgetName: string | undefined): string | null {
    const mealIcons: Record<string, string> = {
      meals_dinner: 'assets/icons/icons-color-soup.svg',
      meals_lunch: 'assets/icons/icons-color-chicken.svg',
      meals_breakfast: 'assets/icons/icons-color-eggs.svg',
      meals_high_tea: 'assets/icons/icons-color-bread.svg',
    };

    return mealIcons[widgetName?.toLowerCase() || ''] || null;
  }

  private getCategoryIcon(category: string, icon: string | undefined): string {
    const categoryIcons: Record<string, string> = {
      attractions: '/assets/images/defaultattractionswide.jpg',
      wellness: '/assets/images/defaultattractionswide.jpg',
      health: '/assets/images/defaultattractionswide.jpg',
      gastronomy: '/assets/images/defaultrestowide.jpg',
    };

    if (categoryIcons[category]) {
      return categoryIcons[category];
    }

    if (icon) {
      return `/assets/icons/${icon}.svg`;
    }

    return '';
  }

  private getStaticIcon(key: string | undefined) {
    switch (key) {
      case 'YOUR_PLACE':
        return 'icons-color-apartment';
      case 'CHAT_WITH_US':
        return 'icons-color-chat';
      case 'LOGO':
        return 'icons-color-logo';
      case 'BOOK_PARKING':
        return 'icons-color-parking';
      case 'BOOK_BREAKFAST':
        return 'icons-color-eggs';
      case 'BOOK_ADDITIONAL_CLEANING':
        return 'icons-color-cleaning';
      case 'ENTRY_INSTRUCTIONS':
        return 'icons-color-key';
      case 'PROFILE':
        return 'icons-user-copy';
      case 'WIFI_COPY_PASSWORD':
        return 'icons-copy';
      case 'OPEN_IN_MAPS':
        return 'icons-chat-copy-5';
      case 'OPEN_SMARTCHAT':
        return 'icons-channels-web-chat';
      case 'OPEN_WHATSAPP':
        return 'icons-channel-whatsapp';
      case 'OPEN_PHONE':
        return 'icons-phone';
      case 'VISIT_WEBSITE':
        return 'icons-social-media-www-color';
      default:
        return '';
    }
  }
}
