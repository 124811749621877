import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, tap } from 'rxjs';
import { ICountry } from '@smarttypes/core';
import urlJoin from 'url-join';

@Injectable({
  providedIn: 'root',
})
export class CountryService {
  private _countries!: ICountry[];
  constructor(private http: HttpClient) {}

  getCountries(base?: string): Observable<ICountry[]> {
    if (this._countries) {
      return of(this._countries);
    }
    const baseApi: string = base ? base : '';

    return this.http.get<ICountry[]>(urlJoin(baseApi, '/countries/all')).pipe(
      tap(res => {
        this._countries = res;
      }),
    );
  }

  getBy2Code(code: string, base?: string): Observable<ICountry | undefined> {
    const baseApi: string = base ? base : '';
    return this.http.get<ICountry>(urlJoin(baseApi, `/countries/by-alpha-2-code/${code.toLowerCase()}`));
  }
}
