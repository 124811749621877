import { Injectable } from '@angular/core';
import { IVisitorsArea } from '@smarttypes/core';
import { WindowService } from './window.service';

@Injectable({
  providedIn: 'root',
})
export class ColorAccentService {
  constructor(private windowService: WindowService) {}

  public initCustomColor(config: IVisitorsArea): void {
    const body = this.windowService.document?.body;
    if (config?.accentColor) {
      body?.classList?.add('custom');
      body?.style?.setProperty('--ga-accent', this.hexToRgb(config.accentColor));
    } else {
      body?.classList?.remove('custom');
      body?.style?.removeProperty('--ga-accent');
    }
  }

  private hexToRgb(hex: string) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (result) {
      const r = parseInt(result[1], 16);
      const g = parseInt(result[2], 16);
      const b = parseInt(result[3], 16);
      return `${r},${g},${b}`;
    }
    return null;
  }
}
