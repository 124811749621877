import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ILanguage } from '@smarttypes/core';
import { ButtonCircleComponent } from '@ui/common/buttons';
import { SelectLanguageComponent } from '@ui/common/forms';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { distinctUntilChanged, fromEvent, map, Subscription, tap } from 'rxjs';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@Component({
  selector: 'wg-form-control-header',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    AngularSvgIconModule,
    ButtonCircleComponent,
    SelectLanguageComponent,
    ReactiveFormsModule,
    NgxSkeletonLoaderModule,
  ],
  templateUrl: './form-control-header.component.html',
  styleUrls: ['./form-control-header.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormControlHeaderComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public title?: string;
  @Input() public backTo?: string[];
  @Input() public languages: ILanguage[] = [];
  @Input() public language?: string;
  @Input() public loading = false;
  @Output() public return: EventEmitter<void> = new EventEmitter<void>();
  @Output() public langChange: EventEmitter<string> = new EventEmitter<string>();
  public langControl = new FormControl();
  public isLanguageSticky = false;
  private subscriptions: Subscription = new Subscription();

  constructor(private readonly _cd: ChangeDetectorRef) {}

  public ngOnInit(): void {
    if (this.language) {
      this.langControl.setValue(this.language);
    }

    this.subscriptions.add(
      this.langControl.valueChanges.subscribe((lang: string) => {
        this.langChange.emit(lang);
      }),
    );

    this.subscriptions.add(
      fromEvent(window, 'scroll')
        .pipe(
          map((e: Event) => {
            const scrollEl = (e.target as Document)?.scrollingElement;
            return (scrollEl?.scrollTop ?? 0) > 106;
          }),
          distinctUntilChanged(),
          tap(state => {
            this.isLanguageSticky = state;
            this._cd.detectChanges();
          }),
        )
        .subscribe(),
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes?.['language']?.currentValue) {
      this.langControl.setValue(changes?.['language']?.currentValue, {
        emitEvent: false,
      });
    }
  }
}
