import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AvatarComponent, LoaderComponent } from '@ui/common';

@Component({
  selector: 'ui-button-rectangle',
  standalone: true,
  imports: [CommonModule, AngularSvgIconModule, AvatarComponent, LoaderComponent],
  templateUrl: './button-rectangle.component.html',
  styleUrls: ['./button-rectangle.component.scss'],
})
export class ButtonRectangleComponent {
  @Input() type: 'primary' | 'secondary' | 'tertiary' | 'danger' | 'whatsapp' | 'white' | 'dark' | 'green' =
    'secondary';
  @Input() size: 's' | 'm' | 'l' = 's';
  @Input() label = '';
  @Input() icon?: string;
  @Input() forceIcon = false;
  @Input() iconSmall = false;
  @Input() arrow = false;
  @Input() disabled = false;
  @Input() block = false;
  @Input() object?: unknown;
  @Input() color = false;
  @Input() avatarKey: 'avatar' | 'logo' = 'avatar';
  @Input() avatarType: 'initials' | 'photo' | 'initialsUser' = 'initialsUser';
  @HostBinding('class.pending') @Input() pending = false;
  @HostBinding('class.pending-too-long') @Input() pendingAnimation = false;
  @HostBinding('class.button-full-width') @Input() forceFullWidth = false;
  @Input() bottomLabel?: string;
  @HostBinding('class.active') @Input() active = false;

  get showArrow(): boolean {
    if (this.size === 'l') {
      return false;
    }
    return this.arrow;
  }

  get showAvatar(): boolean {
    return this.size !== 'l' && !!this.object;
  }

  get iconSize(): string {
    return this.iconSmall ? 'button-icon-small' : '';
  }

  get blockType(): string {
    return this.block ? 'button-block' : '';
  }

  get avatarSize(): 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl' {
    if (this.size === 's') {
      return 'm';
    }
    return 'l';
  }

  get loaderSize(): 's' | 'm' | 'l' | 'xl' {
    if (this.size === 'l') {
      return 'm';
    }
    return 's';
  }

  get loaderColor(): 'dark' | 'white' | 'blue' | 'danger' | 'dark-white' | 'whatsapp' {
    switch (this.type) {
      case 'primary':
        return 'white';
      case 'danger':
        return 'danger';
      case 'secondary':
      case 'tertiary':
      case 'dark':
        return 'dark';
      case 'whatsapp':
        return 'whatsapp';
      default:
        return 'blue';
    }
  }

  get hasIcon(): string {
    return this.arrow || this.icon ? 'has-icon' : '';
  }

  get showIcon(): boolean {
    if (this.icon && this.forceIcon) {
      return true;
    }
    return !!this.icon && (!this.block || !this.object);
  }

  get iconPath(): string {
    return `/assets/icons/${this.icon}.svg`;
  }

  get sizeClass(): string {
    return `button-size-${this.size}`;
  }

  get typeClass(): string {
    return `button-type-${this.type}`;
  }

  get hasBottomLabel(): string {
    return this.bottomLabel ? 'has-bottom-label' : '';
  }
}
