import { Injectable, Optional } from '@angular/core';
import { IAccount, ICompanyRole, IVisitorsArea } from '@smarttypes/core';
import { getUnixTime, parseISO } from 'date-fns';
import { Intercom } from 'ng-intercom';
import { forkJoin, switchMap, take } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { HttpService } from '../http/http.service';
import { AuthService } from './auth.service';
import { CompanyService } from './company.service';
import { AccountService } from './account.service';
import { VisitorsConfiguratorService } from '../../modules/visitors-configurator/visitors-configurator.service';

@Injectable({
  providedIn: 'root',
})
export class IntercomService {
  constructor(
    private readonly httpService: HttpService,
    private readonly authService: AuthService,
    private readonly companyService: CompanyService,
    private readonly accountService: AccountService,
    private readonly visitorsConfService: VisitorsConfiguratorService,
    @Optional() private readonly intercom: Intercom,
  ) {}

  public bootIntercom() {
    console.log('BOT IntercomService');
    this.authService.$loggedIn
      .pipe(
        filter(e => e),
        switchMap(() =>
          forkJoin([
            this.accountService.$currentUser().pipe(take(1)),
            this.companyService.$currentUser().pipe(take(1)),
            this.visitorsConfService.getInitialConfig().pipe(take(1)),
          ]),
        ),
      )
      .subscribe(([user, company, ga]) => {
        // const isSH = user?.email?.includes('@mysmarthotel.com') ?? false;
        console.log('Init intercom');
        if (this.intercom && company.role !== 'service' /* && !isSH*/) {
          if (!user) {
            this.intercom.shutdown();
          } else {
            this.initIntercom(user, company, ga);
          }
        }
      });
  }

  public update(data: unknown) {
    if (this.authService.loggedIn && this.intercom) {
      this.intercom.update(data);
    }
  }

  private initIntercom(user: IAccount, company: ICompanyRole, ga: IVisitorsArea) {
    let name = [user?.person?.firstName, user?.person?.lastName].filter(c => !!c).join(' ');

    if (name.trim() === '') {
      name = user.userName as string;
    }

    if (!name || name.trim() === '') {
      name = user._id as string;
    }

    if (this.intercom) {
      this.intercom.boot({
        app_id: environment.intercomAppId,
        environment: environment.intercomEnvironment,
        email: user.email,
        user_id: user._id as string,
        created_at: getUnixTime(parseISO(user.createdAt as unknown as string)),
        appVersion: environment.appVersion,
        name,
        phone: user.phone,
        language: user.language ?? 'en',
        role: company.role,
        account_status: company.status,
        website: ga.url,
        company: {
          id: company._id as string,
          name: company.name,
          plan: company.plan,
          industry: company.type,
          timezone: company.timezone,
          created_at: getUnixTime(parseISO(company.createdAt as unknown as string)),
          account_status: company.status,
          website: ga.url,
        },
      });
    }
  }
}
