import { CommonModule } from '@angular/common';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { CommunicationChannelEnum, ICommunicationChannelId } from '@smarttypes/core';

@Component({
  selector: 'ui-visitors-channels',
  templateUrl: './visitors-channels.component.html',
  styleUrls: ['./visitors-channels.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  imports: [CommonModule],
  standalone: true,
})
export class VisitorsChannelsComponent {
  @Input() public channels: ICommunicationChannelId[] = [];
  @Input() public small = false;

  channelIcon(channel: CommunicationChannelEnum): string {
    let icon: string;
    switch (channel) {
      case CommunicationChannelEnum.FacebookMessenger:
        icon = 'messenger';
        break;
      case CommunicationChannelEnum.SmartChat:
        icon = 'web';
        break;
      case CommunicationChannelEnum.WhatsApp:
        icon = 'whatsapp';
        break;
      case CommunicationChannelEnum.Sms:
        icon = 'sms';
        break;
      default:
        icon = channel;
        break;
    }
    return icon.toLowerCase();
  }
}
