import { Component, ElementRef, EventEmitter, Input, Output, Renderer2, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { DragNDropDirective } from 'angular-v2-directives';

@Component({
  selector: 'ui-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, AngularSvgIconModule, DragNDropDirective],
})
export class UploaderComponent {
  @ViewChild('uploader', { static: false }) public uploader?: ElementRef;
  @Output() public fileAdded: EventEmitter<File> = new EventEmitter();
  @Input() public formats = '';
  @Input() public minWidth = 0;
  @Input() public minHeight = 0;
  @Input() public maxSize = 0;
  @Input() public header = 'SH.UPLOADER.HEADER';
  @Input() public description = '';
  @Input() public border = true;
  public file?: File;

  constructor(
    private renderer2: Renderer2,
    private toastService: ToastrService,
    private translateService: TranslateService,
  ) {}

  public onFileDragOver(): void {
    this.renderer2.addClass(this.uploader?.nativeElement, 'drag-over');
  }

  public onFileDragLeave(): void {
    this.renderer2.removeClass(this.uploader?.nativeElement, 'drag-over');
  }

  public onFileDragDrop(): void {
    this.renderer2.removeClass(this.uploader?.nativeElement, 'drag-over');
  }

  public onFileDropped(file: any[]): void {
    // FileList[]
    this.validateAndSet(file[0]);
  }

  public fileBrowseHandler(file: File[]): void {
    this.validateAndSet(file[0]);
  }

  private validateAndSet(file: File) {
    if (this.maxSize > 0 && file.size > this.maxSize) {
      this.toastService.error(this.translateService.instant('SH.UPLOADER.IMAGE_IS_TOO_BIG'));
      return;
    }

    if (this.minWidth === 0 && this.minHeight === 0) {
      this.setFile(file);
      return;
    }

    const img = new Image();
    img.src = window.URL.createObjectURL(file);
    img.onload = () => {
      if (img.width < this.minWidth || img.height < this.minHeight) {
        this.toastService.error(this.translateService.instant('SH.UPLOADER.PICTURE_DIMENSIONS_TOO_SMALL'));
        return;
      }
      this.setFile(file);
    };
  }

  private setFile(file: File): void {
    this.fileAdded.emit(file);
    this.file = file;
  }
}
