import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
} from '@angular/core';
import { FormControlDirective } from 'angular-v2-directives';
import { Subscription } from 'rxjs';
import { SelectComponent } from './select/select.component';

@Component({
  selector: 'sh-form',
  templateUrl: './form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormComponent implements AfterContentInit, OnDestroy {
  @Input() icon!: string;
  @Input() iconPosition!: 'left' | 'right';
  @Input() clear!: boolean;
  @Input() noFocus = false;
  @ContentChild(FormControlDirective, { static: true })
  inputChild!: FormControlDirective;
  @ContentChild(SelectComponent, { static: true }) select!: SelectComponent;
  @HostBinding('class') typeClass!: string;
  @HostBinding('class.sh-form') shFormClass = true;
  @HostBinding('class.sh-form-new') @Input() isNew = true;
  private subscription!: Subscription;

  constructor(private cd: ChangeDetectorRef) {}

  @HostBinding('class.ng-focused') get focused() {
    return this.inputChild?.focused;
  }

  @HostBinding('class.ng-disabled') get disabled() {
    return this.inputChild?.disabled;
  }

  @HostBinding('class.ng-untouched') get untouched() {
    return this.checkClass('untouched');
  }

  @HostBinding('class.ng-hasValue') get value() {
    return this.checkClass('value');
  }

  @HostBinding('class.ng-overflowSpinnerState') get pending() {
    return this.checkClass('pending');
  }

  @HostBinding('class.ng-hasPlaceholder') get requplaceholderired() {
    return this.checkFormDirective('placeholder');
  }

  @HostBinding('class.ng-required') get required() {
    return this.checkFormDirective('required');
  }

  @HostBinding('class.ng-invalid') get invalid() {
    return this.checkClass('invalid');
  }

  @HostBinding('class.ng-touched') get touched() {
    return this.checkClass('touched');
  }

  @HostBinding('class.ng-pristine') get pristine() {
    return this.checkClass('pristine');
  }

  @HostBinding('class.ng-dirty') get dirty() {
    return this.checkClass('dirty');
  }

  @HostBinding('class.ng-valid') get valid() {
    return this.checkClass('valid');
  }

  @HostBinding('class.sh-form-disabled') get isDisabled() {
    if (this.select) {
      return this.select?.disabled;
    } else if (this.inputChild) {
      return this.inputChild?.disabled;
    } else {
      return false;
    }
  }

  @HostBinding('class.sh-form-active') get isActive() {
    return this.inputChild ? this.inputChild.focused : false;
  }

  get hasValue() {
    return (
      this.inputChild &&
      this.inputChild.ngControl &&
      this.inputChild.ngControl.value !== undefined &&
      this.inputChild.ngControl.value !== '' &&
      this.inputChild.ngControl.value !== null
    );
  }

  get iconClass() {
    return this.hasValue && this.clear ? 'icon-x' : this.icon;
  }

  @HostListener('click')
  focusInput() {
    if (!this.noFocus && this.inputChild) {
      this.inputChild.focus();
    }
  }

  checkClass(prop: string): boolean {
    const control = this.inputChild ? this.inputChild.ngControl : null;
    if (control && prop === 'value' && Array.isArray((control as any)[prop]) && (control as any)[prop].length === 0) {
      return false;
    }

    return (
      control &&
      ((control as any)[prop] || (prop === 'value' && ((control as any)[prop] === 0 || (control as any)[prop] === 0)))
    );
  }

  checkFormDirective(prop: string) {
    const control = this.inputChild ? this.inputChild : null;
    return control && (control as any)[prop];
  }

  ngAfterContentInit() {
    let className = '';
    if (this.inputChild) {
      className += `ng-type-${this.inputChild.type}`;
      if (this.inputChild && this.inputChild?.ngControl?.valueChanges) {
        this.subscription = this.inputChild.ngControl.valueChanges.subscribe(() => {
          this.cd.detectChanges();
        });
      }
    }
    if (this.icon && this.iconPosition === 'right') {
      className += ' sh-form--icon-right';
    }
    this.typeClass = className;
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
