import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { map, Observable, take } from 'rxjs';
import { filter, timeout } from 'rxjs/operators';
import { CompanyService } from '../services/company.service';
import { CompanyStatusEnum } from '@smarttypes/core';

@Injectable({
  providedIn: 'root',
})
export class PreliminaryTrialGuard implements CanActivate {
  constructor(private readonly companyService: CompanyService, private readonly router: Router) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.companyService.$currentUser().pipe(
      filter(e => !!e),
      take(1),
      timeout(10000),
      map(e => {
        if ((e.status as CompanyStatusEnum) === CompanyStatusEnum.preliminaryTrial) {
          if (this.router.url !== '/guest-area') {
            this.router.navigate(['guest-area']);
          }
          return false;
        }

        return true;
      }),
    );
  }
}
